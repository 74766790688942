import React, {useState, useEffect, useRef} from 'react';
import {WorkType} from '../assets/enums.js';
import AvailableLanguages from '../assets/language';

const Gallery = ({list, animate, columns, mobileMode, lang, ...props}) => {

    const [data, setData] = useState({});
    const [selectedItem, setSelectedItem] = useState(null);
    const translate = AvailableLanguages[lang];
    const videoRef = useRef(null);


    useEffect(() => {

        const items = {count: columns};

        let currColumn = 0;

        for (let i = 0; i < columns; i++) {
            items[i] = [];
        }

        for (let i = 0; i < list.length; i++) {
            items[currColumn].push(list[i]);
            currColumn++;
            if (currColumn >= columns) currColumn = 0;
        }

        setData(items);

    }, [list, columns]);

    const watchVideo = () => {
        if (videoRef.current.requestFullscreen) {
            videoRef.current.requestFullscreen();
        } else if (videoRef.current.mozRequestFullScreen) { /* Firefox */
            videoRef.current.mozRequestFullScreen();
        } else if (videoRef.current.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            videoRef.current.webkitRequestFullscreen();
        } else if (videoRef.current.msRequestFullscreen) { /* IE/Edge */
            videoRef.current.msRequestFullscreen();
        }
    }


    if (!data || !data[0]) return null;

    const cols = !data[columns - 1] ? data.count : columns;
    
    return  <>
                <div className={"gallery" + (animate ? " animate" : "")} {...props}>
                    {Array.from(Array(cols)).map((col, i) =>
                        <div key={i} className="gallery-column" style={{flex: 100 / columns}}>
                            {data[i].map(obj => 
                                <div className="image no-select" onClick={() => setSelectedItem(obj)}>
                                    <img loading="lazy" src={obj.compressedImage} alt=""/>
                                </div>    
                            )}
                        </div>
                    )}
                </div>
                {selectedItem &&
                    <div className="gallery-previewer" onClick={() => setSelectedItem(null)}>
                        <div className="gallery-previewer-container" onClick={e => e.stopPropagation()}>
                            <div className="gallery-previewer-header">
                                {selectedItem.title}
                                <div className="gallery-previewer-close" onClick={() => setSelectedItem(null)}></div>
                            </div>
                            <div className="gallery-previewer-content">
                                {/* <img src={selectedItem.image} alt="" /> */}
                                <div>
                                    {selectedItem.video ?
                                    <>
                                        <video ref={videoRef} autoPlay={!mobileMode} loop={!mobileMode} poster={selectedItem.image} width="100%">
                                            <source src={selectedItem.video} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                        {mobileMode && <div className="gallery-previewer-watch-video">
                                            <div className="button white" onClick={watchVideo}>
                                                {translate["WATCHVIDEO"]}
                                            </div>
                                        </div>}
                                    </>
                                    :
                                    <img loading="lazy" src={selectedItem.image} alt="" />
                                    }
                                </div>
                                <div className="gallery-previewer-info">
                                    <div className="col">
                                        <div className="title">
                                            {translate["DESCRIPTION"]}:
                                        </div>
                                        <div className="text">
                                            {selectedItem.description}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="title">
                                            {translate["MY_ROLES"]}:
                                        </div>
                                        {selectedItem.types.map((b, i) => <div key={`b-${i}`} className={`badge badge-type-${b}`}>
                                            {
                                                b === WorkType.Frontend ? translate["FRONTEND"] :
                                                b === WorkType.FrontendMaintainer ? translate["FRONTEND_MAINTAINER"] :
                                                b === WorkType.BackendMaintainer ? translate["BACKEND_MAINTAINER"] :
                                                b === WorkType.Game ? translate["GAME"] :
                                                b === WorkType.Art ? translate["ART"] :
                                                b === WorkType.Backend ? translate["BACKEND"] :
                                                b === WorkType.Widget ? translate["WIDGET"] :
                                                b === WorkType.Database ? translate["DATABASE"] :
                                                b === WorkType.UIUX ? "UI / UX" :
                                                b === WorkType.App ? translate["APP"] :
                                                b === WorkType.Api ? translate["API"] : ""
                                            }
                                        </div>)}
                                    </div>
                                    {selectedItem.link && <div className="col">
                                        <div className="title">
                                            {translate["LINK_TO_WEBSITE"]}:
                                        </div>
                                        <div className="text">
                                            <a href={selectedItem.link} target="_blank" rel="noopener noreferrer">{selectedItem.link}</a>
                                        </div>
                                    </div>}
                                </div>
                                {/* <video width="960" height="634" autoPlay="" muted="" loop="" playsInline="" poster={selectedItem.image} className="" datasrc={selectedItem.video} datatype="video/mp4"><source src={selectedItem.video} codecs="avc1.42E01E, mp4a.40.2" type="video/mp4" /></video> */}
                            </div>
                        </div>
                    </div>
                }
            </>
 
};

export default React.memo(Gallery, (prevProps, newProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(newProps);
    // if (prevProps)
});