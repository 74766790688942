import React from 'react';

export const HamburguerMenu = React.memo(({open, click, ...rest}) => {

    return (
        <div className="hamburguer-button no-select" onClick={click}>
            <div className="hamburguer-line line-1">

            </div>
            <div className="hamburguer-line line-2">

            </div>
            <div className="hamburguer-line line-3">

            </div>
        </div>
    );
});