import React from 'react';

const ButtonSwitch = ({options, value, onClick, ...props}) => {



    return <div className="switch-button">
        {options.map(e => 
            <div className={e === value ? "selected" : ""} onClick={() => e !== value && onClick(e)}>
                {e}
            </div>    
        )}
    </div>;
 
};

export default React.memo(ButtonSwitch);