
import davidImg from '../assets/david.jpg';

import cv_pt from '../assets/pdf/pt.pdf';
import cv_en from '../assets/pdf/en.pdf';

import airdeskImg from '../assets/airdesk.png';
import getcompliedImg from '../assets/getcomplied.png';
import manuaisescolaresImg from '../assets/manuaisescolares.png';
import loyaltyAppImg from '../assets/loyalty-app.png';
import loyaltyWebImg from '../assets/loyalty-web.png';
import radicalchessImg from '../assets/radicalchess.png';
import smartcityImg from '../assets/smartcity.png';
import winesOfPortugalImg from '../assets/wines-of-portugal.png';
import poupaEnergiaImg from '../assets/poupa-energia.png';
import riaDeAveiroImg from '../assets/ria-de-aveiro.png';
import geoparqueWebsiteImg from '../assets/geoparque-website.png';
import geoparqueMesaImg from '../assets/geoparque-mesa.png';
import campingImg from '../assets/camping.png';
import casaMaisImg from '../assets/casamais.png';
import plannerImg from '../assets/planner.png';
import multiwasherImg from '../assets/multiwasher.png';
import whistleblowerImg from '../assets/whistleblower.png';
import whistleblowerFrontImg from '../assets/whistleblower-front.png';
import timorINSSImg from '../assets/timor-inss.png';
import ogpredictImg from '../assets/ogpredict.png';
import hardsecureImg from '../assets/hardsecure.png';
import hardsecureStrapiImg from '../assets/hardsecure-strapi.png';
import intelclueBackofficeImg from '../assets/intelclue-backoffice.png';
import intelclueOutlookImg from '../assets/intelclue-outlook.png';
import ccvBackofficeImg from '../assets/ccv-backoffice.png';
import ccvAppImg from '../assets/ccv-app.png';
import inssStrapiImg from '../assets/inss-strapi.png';

import airdeskCompressedImg from '../assets/airdesk-compressed.jpg';
import getcompliedCompressedImg from '../assets/getcomplied-compressed.jpg';
import manuaisescolaresCompressedImg from '../assets/manuaisescolares-compressed.jpg';
import loyaltyAppCompressedImg from '../assets/loyalty-app-compressed.jpg';
import loyaltyWebCompressedImg from '../assets/loyalty-web-compressed.jpg';
import radicalchessCompressedImg from '../assets/radicalchess-compressed.png';
import smartcityCompressedImg from '../assets/smartcity-compressed.jpg';
import winesOfPortugalCompressedImg from '../assets/wines-of-portugal-compressed.jpg';
import poupaEnergiaCompressedImg from '../assets/poupa-energia-compressed.jpg';
import riaDeAveiroCompressedImg from '../assets/ria-de-aveiro-compressed.jpg';
import geoparqueWebsiteCompressedImg from '../assets/geoparque-website-compressed.jpg';
import geoparqueMesaCompressedImg from '../assets/geoparque-mesa-compressed.jpg';
import campingCompressedImg from '../assets/camping-compressed.jpg';
import casaMaisCompressedImg from '../assets/casamais-compressed.jpg';
import plannerCompressedImg from '../assets/planner-compressed.jpg';
import multiwasherCompressedImg from '../assets/multiwasher-compressed.jpg';
import whistleblowerCompressedImg from '../assets/whistleblower-compressed.png';
import whistleblowerFrontCompressedImg from '../assets/whistleblower-front-compressed.png';
import timorINSSCompressedImg from '../assets/timor-inss-compressed.png';
import ogpredictCompressedImg from '../assets/ogpredict-compressed.png';
import hardsecureCompressedImg from '../assets/hardsecure-compressed.png';
import hardsecureStrapiCompressedImg from '../assets/hardsecure-strapi-compressed.png';
import intelclueBackofficeCompressedImg from '../assets/intelclue-backoffice-compressed.png';
import intelclueOutlookCompressedImg from '../assets/intelclue-outlook-compressed.png';
import ccvBackofficeCompressedImg from '../assets/ccv-backoffice-compressed.png';
import ccvAppCompressedImg from '../assets/ccv-app-compressed.png';
import inssStrapiCompressedImg from '../assets/inss-strapi-compressed.png';


// import airdeskVideo from '../assets/airdesk.mp4';
import getcompliedVideo from '../assets/getcomplied.mp4';
import manuaisescolaresVideo from '../assets/manuaisescolares.mp4';
import loyaltyAppVideo from '../assets/loyalty-app.mp4';
import loyaltyWebVideo from '../assets/loyalty-web.mp4';
// import brainiacchessVideo from '../assets/brainiacchess.mp4';
import smartcityVideo from '../assets/smartcity.mp4';
import plannerVideo from '../assets/planner.mp4';
import campingVideo from '../assets/camping.mp4';
import geoparqueMesaVideo from '../assets/geoparque-mesa.mp4';
import multiwasherVideo from '../assets/multiwasher.mp4';

import emailPhp from '../email.php';


import {WorkType, MenuTab} from '../assets/enums.js';

import React, {useState, useRef, useMemo } from 'react';
import FloatingArea from '../components/FloatingArea';
import FunnyCounter from '../components/FunnyCounter';
import Gallery from '../components/Gallery';
import { ServerDevelopment, GameDevelopment, WebDevelopment, DesktopDevelopment, Unity, Godot, HtmlCssJss, JQuery, ReactImg, DotNet, Node, Electron, AppDevelopment, ContactHome, ContactPhone, ContactEmail, LoaderContainer, CounterLinesOfCode, CounterHours, CounterCoffees } from '../components/Images';
import FullScreenArea from '../components/FullScreenArea';
import FullPageContainer from '../components/FullPageContainer';
// import AboutMe from './AboutMe/AboutMe';
// import Header from './Header';
import Loading from '../components/Loading';
import CircleBar from '../components/CircleBar';
import IAm from '../components/IAm';
import Timeline from '../components/Timeline';
import ToopTip from '../components/ToolTip';
import { Menu } from '../components/Menu';
import AvailableLanguages from '../assets/language';
import { useWindowSize } from '../hooks/events';
import SubmitButton from '../components/SubmitButton';
import ButtonSwitch from '../components/ButtonSwitch';

const scrollToRef = (parent, top) => parent.current.scroll({left: 0, top: top, behavior: 'smooth'})


const FullPage = () => {

    const [loading, setLoading] = useState(true);
    // const [selectedItem, setSelectedItem] = useState(1);
    const [contactOpened, setContactOpened] = useState(false);
    const [menuFocused, setMenuFocused] = useState(false);
    // const [porfolioMenuItem, setPorfolioMenuItem] = useState(WorkType.All);
    const [menuTab, setMenuTab] = useState(MenuTab.Home);
    const [cvPdf] = useState(() => {
        return {
            'en': cv_en,
            'pt': cv_pt
        }
    });
    const [lang, setLanguage] = useState(() => {
        try {
            const browserLang = (navigator.language || navigator.userLanguage).match(/[\w]+/)[0];
            if (AvailableLanguages[browserLang]) return browserLang;
        } catch (error) {
        }
        return 'en';
    });
    const translate = useMemo(() => AvailableLanguages[lang], [lang]);
    const screenSize = useWindowSize();
    const [formErrors, setFormErrors] = useState({
        email: false,
        name: false,
        subject: false,
        message: false
    });
    
    const [animations, setAnimations] = useState({
        anim_initial: false,
        anim_loading: false,
        anim_about_me: false,
        anim_lang_skills: false,
        anim_lang_skills2: false,
        timeline: false,
        what_i_can_offer: false,
        funny_counters: false,
        portfolio: false
    });


    const scrollContainer = useRef(null);
    const aboutMeRef = useRef(null);

    const skillsRef = useRef(null);
    const skills2Ref = useRef(null);
    const timelineRef = useRef(null);
    const offersRef = useRef(null);
    const countersRef = useRef(null);
    const portfolioRef = useRef(null);
    const contactsRef = useRef(null);
    
    const formNameRef = useRef(null);
    const formEmailRef = useRef(null);
    const formSubjectRef = useRef(null);
    const formMessageRef = useRef(null);

    const hoursOfWork = useMemo(() => (Math.ceil(Math.abs(new Date() - new Date('09/20/2016')) / (1000 * 60 * 60 * 24))) * 8, []);


    // const header1 = useRef(null);
    // const header2 = useRef(null);
    // const header3 = useRef(null);
    // const header4 = useRef(null);


    // const porfolioMenuItems = [{
    //     id: WorkType.All,
    //     name: translate["ALL"]
    // },{
    //     id: WorkType.Website,
    //     name: translate["WEBSITES"]
    // },{
    //     id: WorkType.Server,
    //     name: translate["SERVERS"]
    // },{
    //     id: WorkType.Game,
    //     name: translate["GAMES"]
    // },{
    //     id: WorkType.MobileApp,
    //     name: translate["MOBILE_APPS"]
    // },{
    //     id: WorkType.DesktopApp,
    //     name: translate["DESKTOP_APPS"]
    // },{
    //     id: WorkType.PixelArt,
    //     name: translate["ARTS"]
    // }]

    // useEffect(() => {
    //     document.addEventListener('readystatechange', event => {
    //         if (event.target.readyState === "complete") {  //or at "complete" if you want it to execute in the most last state of window.
    //             setTimeout(() => ReLoadImages(), 1200);
    //         }
    //     });
    // }, []);

    // const ReLoadImages = () => {
    //     const imgs = document.querySelectorAll('img[data-lazysrc]');

    //     imgs.forEach(img => {
    //         img.src = img.getAttribute('data-lazysrc');
    //     });
    // }


    const porfolioData = useMemo(() => [
        {
            image: airdeskImg,
            compressedImage: airdeskCompressedImg,
            // video: airdeskVideo,
            title: "Airdesk",
            description: translate["AIRDESK_DESCRIPTION"],
            link: "https://airdesk.ai",
            types: [WorkType.Api, WorkType.Database, WorkType.Frontend]
        },
        {
            image: getcompliedImg,
            compressedImage: getcompliedCompressedImg,
            video: getcompliedVideo,
            title: "GetComplied",
            description: translate["GETCOMPLIED_DESCRIPTION"],
            link: "https://getcomplied.com",
            types: [WorkType.Widget]
        },
        {
            image: manuaisescolaresImg,
            compressedImage: manuaisescolaresCompressedImg,
            video: manuaisescolaresVideo,
            title: "Manuais Escolares",
            description: translate["MANUAISESCOLARES_DESCRIPTION"],
            link: "https://manuaisescolares.pt",
            types: [WorkType.Frontend]
        },
        {
            image: loyaltyWebImg,
            compressedImage: loyaltyWebCompressedImg,
            video: loyaltyWebVideo,
            title: "Loyalty - Web",
            description: translate["LOYALTY_DESCRIPTION"],
            types: [WorkType.Frontend]
        },
        {
            image: loyaltyAppImg,
            compressedImage: loyaltyAppCompressedImg,
            video: loyaltyAppVideo,
            title: "Loyalty - App",
            description: translate["LOYALTY_DESCRIPTION"],
            types: [WorkType.App]
        },
        {
            image: radicalchessImg,
            compressedImage: radicalchessCompressedImg,
            // video: loyaltyVideo,
            title: "Radical Chess",
            description: translate["BRAINIACCHESS_DESCRIPTION"],
            link: "https://radicalchess.com",
            types: [WorkType.Api, WorkType.Database, WorkType.FrontendMaintainer, WorkType.BackendMaintainer]
        },
        {
            image: smartcityImg,
            compressedImage: smartcityCompressedImg,
            video: smartcityVideo,
            title: "Smartcity",
            description: translate["SMARTCITY_DESCRIPTION"],
            link: "https://smartcity.unyk.tv/?id=4ea272e",
            types: [WorkType.Frontend]
        },
        {
            image: winesOfPortugalImg,
            compressedImage: winesOfPortugalCompressedImg,
            title: "Wines of Portugal",
            description: translate["WINESOFPORTUGAL_DESCRIPTION"],
            link: "https://winesofportugal.com/en/",
            types: [WorkType.Frontend]
        },
        {
            image: poupaEnergiaImg,
            compressedImage: poupaEnergiaCompressedImg,
            title: "Poupa Energia",
            description: translate["POUPAENERGIA_DESCRIPTION"],
            link: "https://poupaenergia.pt/",
            types: [WorkType.FrontendMaintainer]
        },
        {
            image: riaDeAveiroImg,
            compressedImage: riaDeAveiroCompressedImg,
            title: "Ria de Aveiro",
            description: translate["RIADEAVEIRO_DESCRIPTION"],
            link: "https://granderota.riadeaveiro.pt/",
            types: [WorkType.Frontend]
        },
        {
            image: geoparqueWebsiteImg,
            compressedImage: geoparqueWebsiteCompressedImg,
            title: "Geoparque",
            description: translate["GEOPARQUE_DESCRIPTION"],
            link: "https://www.geoparquelitoralviana.pt/",
            types: [WorkType.FrontendMaintainer]
        },
        {
            image: plannerImg,
            compressedImage: plannerCompressedImg,
            video: plannerVideo,
            title: "Planner",
            description: translate["PLANNER_DESCRIPTION"],
            // link: "https://smartcity.unyk.tv/?id=4ea272e",
            types: [WorkType.Widget]
        },
        {
            image: campingImg,
            compressedImage: campingCompressedImg,
            video: campingVideo,
            title: "Outdoor Routes",
            description: translate["CAMPING_DESCRIPTION"],
            link: "https://area-reservada.outdoor-routes.pt/",
            types: [WorkType.Frontend]
        },
        {
            image: geoparqueMesaImg,
            compressedImage: geoparqueMesaCompressedImg,
            video: geoparqueMesaVideo,
            title: "Geoparque - Mesa Interativa",
            description: translate["GEOPARQUETABLE_DESCRIPTION"],
            // link: "https://smartcity.unyk.tv/?id=4ea272e",
            types: [WorkType.FrontendMaintainer]
        },
        {
            image: casaMaisImg,
            compressedImage: casaMaisCompressedImg,
            // video: smartcityVideo,
            title: "Casa+",
            description: translate["CASAMAIS_DESCRIPTION"],
            link: "https://portalcasamais.pt/",
            types: [WorkType.FrontendMaintainer]
        },
        {
            image: multiwasherImg,
            compressedImage: multiwasherCompressedImg,
            video: multiwasherVideo,
            title: "Multiwasher Studies",
            description: translate['MULTIWASHER_DESCRIPTION'],
            // link: "https://portalcasamais.pt/",
            types: [WorkType.FrontendMaintainer]
        },
        {
            image: timorINSSImg,
            compressedImage: timorINSSCompressedImg,
            // video: multiwasherVideo,
            title: "Timor INSS",
            description: translate['TIMOR_DESCRIPTION'],
            // link: "https://portalcasamais.pt/",
            types: [WorkType.FrontendMaintainer, WorkType.BackendMaintainer, WorkType.Database, WorkType.Api]
        },
        {
            image: whistleblowerImg,
            compressedImage: whistleblowerCompressedImg,
            // video: multiwasherVideo,
            title: "Whistleblower - Backoffice",
            description: translate['WHISTLEBLOWER_BACKOFFICE_DESCRIPTION'],
            // link: "https://portalcasamais.pt/",
            types: [WorkType.Frontend, WorkType.Api, WorkType.Database]
        },
        {
            image: whistleblowerFrontImg,
            compressedImage: whistleblowerFrontCompressedImg,
            // video: multiwasherVideo,
            title: "Whistleblower",
            description: translate['WHISTLEBLOWER_DESCRIPTION'],
            // link: "https://portalcasamais.pt/",
            types: [WorkType.Frontend, WorkType.Api, WorkType.Database]
        },
        {
            image: ogpredictImg,
            compressedImage: ogpredictCompressedImg,
            // video: multiwasherVideo,
            title: "OG Predict",
            description: translate['OGPREDICT_DESCRIPTION'],
            link: "https://ogpredict.com/",
            types: [WorkType.Frontend, WorkType.Backend, WorkType.Api, WorkType.Database]
        },
        {
            image: hardsecureImg,
            compressedImage: hardsecureCompressedImg,
            // video: multiwasherVideo,
            title: "Hardsecure",
            description: translate['HARDSECURE_DESCRIPTION'],
            link: "https://hardsecure.pt/",
            types: [WorkType.FrontendMaintainer]
        },
        {
            image: hardsecureStrapiImg,
            compressedImage: hardsecureStrapiCompressedImg,
            // video: multiwasherVideo,
            title: "Hardsecure - Strapi",
            description: translate['HARDSECURE_STRAPI_DESCRIPTION'],
            types: [WorkType.FrontendMaintainer, WorkType.BackendMaintainer, WorkType.Api, WorkType.Database]
        },
        {
            image: inssStrapiImg,
            compressedImage: inssStrapiCompressedImg,
            // video: multiwasherVideo,
            title: "INSS",
            description: translate['INSS_STRAPI_DESCRIPTION'],
            types: [WorkType.FrontendMaintainer, WorkType.BackendMaintainer, WorkType.Api, WorkType.Database]
        },
        {
            image: ccvBackofficeImg,
            compressedImage: ccvBackofficeCompressedImg,
            // video: multiwasherVideo,
            title: "CCV",
            description: translate['CCV_BACKEND_DESCRIPTION'],
            types: [WorkType.FrontendMaintainer, WorkType.BackendMaintainer, WorkType.Api, WorkType.Database]
        },
        {
            image: ccvAppImg,
            compressedImage: ccvAppCompressedImg,
            // video: multiwasherVideo,
            title: "CCV - Desktop App",
            description: translate['CCV_APP_DESCRIPTION'],
            types: [WorkType.Frontend]
        },
        {
            image: intelclueBackofficeImg,
            compressedImage: intelclueBackofficeCompressedImg,
            // video: multiwasherVideo,
            title: "Intelclue - Backoffice",
            description: translate['INTELCLUE_BACKOFFICE_DESCRIPTION'],
            types: [WorkType.Frontend, WorkType.Api, WorkType.Database]
        },
        {
            image: intelclueOutlookImg,
            compressedImage: intelclueOutlookCompressedImg,
            // video: multiwasherVideo,
            title: "Intelclue - Outlook plugin",
            description: translate['INTELCLUE_OUTLOOK_DESCRIPTION'],
            types: [WorkType.Frontend, WorkType.Api]
        }
    ], [translate]);


    if (loading) {
        setTimeout(() => {
            var anim = {...animations};
            
            if (anim.anim_loading) return;

            anim.anim_loading = true;
            anim.anim_initial = true;
            
            setAnimations(anim);

            setTimeout(() => {
                setLoading(false);
            }, 1200);


        }, 1200);
    }

    const scrollGap = 30;
    const menuClick = (headerItem) => {
        switch(headerItem) {
            case MenuTab.Home: scrollToRef(scrollContainer, 0);
            break;
            case MenuTab.AboutMe: scrollToRef(scrollContainer, aboutMeRef.current.offsetTop - 100);
            break;
            case MenuTab.LanguageSkills: scrollToRef(scrollContainer, skillsRef.current.offsetTop - scrollGap);
            break;
            case MenuTab.MyTimeline: scrollToRef(scrollContainer, timelineRef.current.offsetTop - scrollGap);
            break;
            case MenuTab.MyOffers: scrollToRef(scrollContainer, offersRef.current.offsetTop - scrollGap);
            break;
            case MenuTab.Portfolio: scrollToRef(scrollContainer, portfolioRef.current.offsetTop - scrollGap);
            break;
            case MenuTab.Contacts: scrollToRef(scrollContainer, contactsRef.current.offsetTop - scrollGap);
            break;
            default:
                break;
        }
    }

    const onPageScroll = (el) => {

        // if(el.target !== scrollContainer.current) return;

        const top = el.target.scrollTop;
        const screenHeightHalf2 = screenSize[1] / 2;
        const screenHeightHalf70 = screenSize[1] / 1.3;
        const screenHeightHalf60 = screenSize[1] / 1.4;

        if (loading) return;

        // console.log(top);

        let anim = {...animations};
        let changed = false;

        if ((top === 0 && menuFocused) || (top !== 0 && !menuFocused)) {
            setMenuFocused(!menuFocused);
        }

        // if (top >= 300 && !anim.anim_about_me)
        if (top >= aboutMeRef.current.offsetTop - screenHeightHalf70 && !anim.anim_about_me)
        {
            anim.anim_about_me = true;
            changed = true;
        }
        if (top >= skillsRef.current.offsetTop - screenHeightHalf60 && !anim.anim_lang_skills)
        {
            anim.anim_lang_skills = true;
            changed = true;
        }
        if (top >= skills2Ref.current.offsetTop - screenHeightHalf70 && !anim.anim_lang_skills2)
        {
            anim.anim_lang_skills2 = true;
            changed = true;
        }
        if (top >= timelineRef.current.offsetTop - screenHeightHalf70 && !anim.timeline)
        {
            anim.timeline = true;
            changed = true;
        }
        if (top >= offersRef.current.offsetTop - screenHeightHalf70 && !anim.what_i_can_offer)
        {
            anim.what_i_can_offer = true;
            changed = true;
        }
        if (top >= countersRef.current.offsetTop - screenHeightHalf70 && !anim.funny_counters) {
            anim.funny_counters = true;
            changed = true;
        }
        if (top >= portfolioRef.current.offsetTop - screenHeightHalf60 && !anim.portfolio)
        {
            anim.portfolio = true;
            changed = true;
        }

        if (changed) setAnimations(anim);



        if (top < aboutMeRef.current.offsetTop - screenHeightHalf2 - 100 && menuTab !== MenuTab.Home) {
            setMenuTab(MenuTab.Home);
        }
        else if (top >= aboutMeRef.current.offsetTop - screenHeightHalf2 - 100 && top < skillsRef.current.offsetTop - screenHeightHalf2 - scrollGap && menuTab !== MenuTab.AboutMe) {
            setMenuTab(MenuTab.AboutMe);
        }
        // else if (top > 1300 && top < 1600) {
        //     setMenuTab(MenuTab.LanguageSkills);
        // }
        // else if (top > 1600 && top < 1900) {
        //     setMenuTab(MenuTab.Tools);
        // }
        else if (top >= skillsRef.current.offsetTop - screenHeightHalf2 - scrollGap && top < timelineRef.current.offsetTop - screenHeightHalf2 - scrollGap && menuTab !== MenuTab.LanguageSkills) {
            setMenuTab(MenuTab.LanguageSkills);
        }
        else if (top >= timelineRef.current.offsetTop - screenHeightHalf2 - scrollGap && top < offersRef.current.offsetTop - screenHeightHalf2 - scrollGap && menuTab !== MenuTab.MyTimeline) {
            setMenuTab(MenuTab.MyTimeline);
        }
        else if (top >= offersRef.current.offsetTop - screenHeightHalf2 - scrollGap && top < portfolioRef.current.offsetTop - screenHeightHalf2 - scrollGap && menuTab !== MenuTab.MyOffers) {
            setMenuTab(MenuTab.MyOffers);
        }
        else if (top >= portfolioRef.current.offsetTop - screenHeightHalf2 - scrollGap && top < contactsRef.current.offsetTop - screenHeightHalf2 - scrollGap && menuTab !== MenuTab.Portfolio) {
            setMenuTab(MenuTab.Portfolio);
        }
        else if (top >= contactsRef.current.offsetTop - screenHeightHalf2 - scrollGap && menuTab !== MenuTab.Contacts) {
            setMenuTab(MenuTab.Contacts);
        }


        // const range = 100;
        
        // if (top < header1.current.offsetTop + range) setSelectedItem(1);
        // else if (top < header2.current.offsetTop + range && top > header2.current.offsetTop - range) setSelectedItem(2);
        // else if (top < header3.current.offsetTop + range && top > header3.current.offsetTop - range) setSelectedItem(3);
        // else if (top < header4.current.offsetTop + range && top > header4.current.offsetTop - range) setSelectedItem(4);
    }

    const validateForm = () => {
        const name = formNameRef.current.value;
        const email = formEmailRef.current.value;
        const subject = formSubjectRef.current.value;
        const message = formMessageRef.current.value;

        let errorsData = {
            name: false,
            email: false,
            subject: false,
            message: false
        };
        
        if (!name) {
            errorsData.name = true;
        }
        if (!email || !/[\S]+@[\S]+[.][\w]{2,}/.test(email)) {
            errorsData.email = true;
        }
        if (!subject) {
            errorsData.subject = true;
        }
        if (!message) {
            errorsData.message = true;
        }

        if (formErrors.name !== errorsData.name ||
            formErrors.email !== errorsData.email ||
            formErrors.subject !== errorsData.subject ||
            formErrors.message !== errorsData.message)
            setFormErrors(errorsData);

        
        return !errorsData.name && !errorsData.email && !errorsData.subject && !errorsData.message;
    };

    const sendEmail = async (fetchedCallback) => {
        const name = formNameRef.current.value;
        const email = formEmailRef.current.value;
        const subject = formSubjectRef.current.value;
        const message = formMessageRef.current.value;

        const response = await fetch(emailPhp, {
            method: 'post',
            body: JSON.stringify({
                Name: name,
                Email: email,
                Subject: subject,
                Message: message
            })
        });

        fetchedCallback(response.status === 200);

    }


    const maxScreenSize = (window.innerWidth > window.innerHeight ? window.innerWidth : window.innerHeight) + 300;

    const scale = maxScreenSize/60;
    
    const galleryCols = screenSize[0] <= 900 ? 1 :
                        screenSize[0] <= 1200 ? 2 :
                        screenSize[0] <= 1600 ? 3 :
                        4;

    const isMobile = screenSize[0] < 990;

    return (
        <FullPageContainer onPageScroll={onPageScroll} reff={scrollContainer} className={(loading ? 'loading' : 'loaded') + (isMobile ? " mobile" : "")}>
            {loading && <LoaderContainer animate={animations.anim_loading} scale={scale} color={"#424242"} />}
            {/* {loading && <div className="loading-area vertical-center text-align-center position-relative w100">
                <div className={"loading-container" + (animations.anim_loading ? " animate" : "" )} style={{width: animations.anim_loading ? maxScreenSize : null, height: animations.anim_loading ? maxScreenSize : null}}>
                    <Loading width={50} color="#424242" />
                </div>
            </div>} */}
            {loading && 
                <div className={"dots-loading-container" + (animations.anim_loading ? " animate" : "" )}>
                    <Loading width={50} color="#424242" />
                </div>
            }

            <Menu hamburguerMode={screenSize[0] < 1000} lang={lang} menuClick={menuClick} focused={menuFocused} tab={menuTab} />
            <FullScreenArea extraHeight={20} className={(animations.anim_loading ? "animate" : "" )} style={{backgroundColor: "#ececec"}}>
                <div className="iam-container">
                    <div className="title">
                        {translate["HELLO_IM"]}
                    </div>
                    <div className="title bold">
                        David Serôdio
                    </div>
                    {/* <div className="title" style={{fontSize: 18, marginTop: 10}}>
                        Welcome to my website
                    </div> */}
                    <IAm animate={animations.anim_initial} lang={lang} className="title iam" style={{color: "#3caea3"}} textsClassName="bold" preText={translate["IAMA"]} texts={[translate["FULLSTACK_DEVELOPER"], translate["GAME_DEVELOPER"], translate["3D_MODELER"]]} speed={170} removeSpeed={40} delayWhenDone={2000} delayWhenStart={200} />
                </div>
                <div className="left-square"></div>
                <div className={"pic-container" + (menuTab >= MenuTab.LanguageSkills ? " pause" : "")}>
                    <FloatingArea disableAnimations={screenSize[0] <= 1400} animate={animations.anim_initial} />
                </div>
                {/* <Pattern /> */}
            </FullScreenArea>
            
            {/* <FullScreenArea> */}

            {/* <div className="text-align-center position-relative" style={{padding: "50px 20%", color: "#f9f9f9", backgroundColor: "#424242", height: 350, alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center"}}> */}
            <div style={{position: "absolute", height: 150, width: "70%", backgroundColor: "#ececec"}}></div>
            <div className="divisor-1"></div>

            <div ref={aboutMeRef} className={"text-align-center position-relative about-me-container" + (animations.anim_about_me ? " animate" : "")}>
                <div>
                    <div className="title bold" style={{marginBottom: 20, color: "#424242"}}>
                        {translate["ABOUT_ME"]}
                    </div>
                    <div className="my-picture">
                        <img alt="" loading="lazy" src={davidImg} />
                    </div>
                    <div className="about-me-text" dangerouslySetInnerHTML={{__html: translate["ABOUT_ME_TEXT"].replace("{YEARS}", new Date().getFullYear() - 2016)}}></div>
                    <a href={cvPdf[lang]} download target="_blank" rel="noopener noreferrer" className="button title" style={{marginTop: 50}}>
                        {translate["DOWNLOAD_CV"]}
                    </a>
                </div>
            </div>
            {/* </FullScreenArea> */}
            
            {/* <Pattern style={{position:"relative", transform: "rotate(180deg)", bottom:0}} /> */}

            <div ref={skillsRef} className="text-align-center position-relative" style={{padding: "100px 0 50px", marginTop: 50}}>
                <div className="title bold" style={{marginBottom: 50}}>
                    {translate["SKILLS"]}
                </div>
                <div className="circular-progress-container">
                    {/* <CircleBar radius={55} stroke={5} progress={88} color="#ff9f12" rotate={25} style={{width: 154}} subtitle="SQL Server / MySql" />
                    <CircleBar radius={55} stroke={5} progress={90} color="#e44446" rotate={25} style={{width: 154}} subtitle="HTML / CSS" />
                    <CircleBar radius={55} stroke={5} progress={91} color="#7E84F3" rotate={25} style={{width: 154}} subtitle="JAVASCRIPT" />
                    <CircleBar radius={55} stroke={5} progress={93} color="#FAC747" rotate={25} style={{width: 154}} subtitle="C#" />
                    <CircleBar radius={55} stroke={5} progress={60} color="#20639B" rotate={25} style={{width: 154}} subtitle="Python" /> */}
                    
                    {/* <CircleBar radius={55} stroke={5} progress={88} color="#39af3d" rotate={25} style={{width: 154}} subtitle="SQL Server / MySql" />
                    <CircleBar radius={55} stroke={5} progress={90} color="#229426" rotate={25} style={{width: 154}} subtitle="HTML / CSS" />
                    <CircleBar radius={55} stroke={5} progress={91} color="#229426" rotate={25} style={{width: 154}} subtitle="JAVASCRIPT" />
                    <CircleBar radius={55} stroke={5} progress={93} color="#229426" rotate={25} style={{width: 154}} subtitle="C#" />
                    <CircleBar radius={55} stroke={5} progress={60} color="#ff9614" rotate={25} style={{width: 154}} subtitle="Python" /> */}
                    
                    <CircleBar radius={55} stroke={5} progress={93} heatmap animate={animations.anim_lang_skills} rotate={25} style={{width: 154}} subtitle="C#" />
                    <CircleBar radius={55} stroke={5} progress={91} heatmap animate={animations.anim_lang_skills} rotate={25} style={{width: 154}} subtitle="JAVASCRIPT" />
                    <CircleBar radius={55} stroke={5} progress={90} heatmap animate={animations.anim_lang_skills} rotate={25} style={{width: 154}} subtitle="HTML / CSS" />
                    <CircleBar radius={55} stroke={5} progress={88} heatmap animate={animations.anim_lang_skills} rotate={25} style={{width: 154}} subtitle="SQL Server" />
                    <CircleBar radius={55} stroke={5} progress={86} heatmap animate={animations.anim_lang_skills} rotate={25} style={{width: 154}} subtitle="MySql" />
                    <CircleBar radius={55} stroke={5} progress={85} heatmap animate={animations.anim_lang_skills} rotate={25} style={{width: 154}} subtitle="Regex" />
                    <CircleBar radius={55} stroke={5} progress={60} heatmap animate={animations.anim_lang_skills} rotate={25} style={{width: 154}} subtitle="Python" />

                </div>
            </div>
            <div ref={skills2Ref} className="text-align-center position-relative" style={{padding: "50px 0 70px"}}>
                <div className="title bold" style={{marginBottom: 50}}>
                    {translate["TOOLS"]}
                </div>
                <div className="circular-progress-container">
                    {/* <CircleBar radius={55} stroke={5} progress={88} color="#cf62e2" rotate={25} style={{width: 154}} subtitle="Git" />
                    <CircleBar radius={55} stroke={5} progress={59} color="#2ca1d6" rotate={25} style={{width: 154}} subtitle="Aseprite" />
                    <CircleBar radius={55} stroke={5} progress={63} color="#827a7a" rotate={25} style={{width: 154}} subtitle="Adobe XD" />
                    <CircleBar radius={55} stroke={5} progress={85} color="#70b520" rotate={25} style={{width: 154}} subtitle="Visual Studio" />
                    <CircleBar radius={55} stroke={5} progress={80} color="#9c796c" rotate={25} style={{width: 154}} subtitle="Visual Studio Code" />
                    <CircleBar radius={55} stroke={5} progress={68} color="#279ead" rotate={25} style={{width: 154}} subtitle="LINQPad" />
                    <CircleBar radius={55} stroke={5} progress={78} color="#7c34a0" rotate={25} style={{width: 154}} subtitle="SQL Server / MySQL Workbench" /> */}

                    <CircleBar radius={55} stroke={5} progress={85} heatmap animate={animations.anim_lang_skills2} rotate={25} style={{width: 154}} subtitle="Visual Studio" />
                    <CircleBar radius={55} stroke={5} progress={83} heatmap animate={animations.anim_lang_skills2} rotate={25} style={{width: 154}} subtitle="Git" />
                    <CircleBar radius={55} stroke={5} progress={80} heatmap animate={animations.anim_lang_skills2} rotate={25} style={{width: 154}} subtitle="Visual Studio Code" />
                    <CircleBar radius={55} stroke={5} progress={78} heatmap animate={animations.anim_lang_skills2} rotate={25} style={{width: 154}} subtitle="MSSQL / MySQL Workbench" />
                    <CircleBar radius={55} stroke={5} progress={68} heatmap animate={animations.anim_lang_skills2} rotate={25} style={{width: 154}} subtitle="Fusion 360" />
                    <CircleBar radius={55} stroke={5} progress={63} heatmap animate={animations.anim_lang_skills2} rotate={25} style={{width: 154}} subtitle="Adobe XD" />
                    <CircleBar radius={55} stroke={5} progress={59} heatmap animate={animations.anim_lang_skills2} rotate={25} style={{width: 154}} subtitle="Adobe Illustrator" />

                </div>
            </div>

            <div ref={timelineRef} className={"text-align-center position-relative timeline-container" + (animations.timeline ? " animate" : "")} style={{padding: "50px 0 53px", marginTop: 50}}>
                <div className="left-circle-timeline"></div>
                <div className="right-square-timeline"></div>
                <div className="right-circle-timeline"></div>
                <Timeline screenWidth={screenSize[0]} smallMode={screenSize[0] < 1050} lang={lang} animate={animations.timeline} />
            </div>
            <div style={{position:"absolute", width: "100%", height: 300, marginTop: -200, backgroundColor: "#ececec", zIndex: -1}}></div>
            <div style={{position:"absolute", width: "120%", height: 500, marginTop: -500, backgroundColor: "#ececec", zIndex: -1, transform: "rotate(-5deg)"}}></div>
            <div ref={offersRef} style={{backgroundColor: "#ececec"}}>
                <div className={"text-align-center position-relative what-i-can-offer" + (animations.what_i_can_offer ? " animate" : "")} style={{padding: "100px 0 50px"}}>
                    <div className="title bold" style={{marginBottom: 70}}>
                        {translate["WHAT_I_CAN_OFFER"]}
                    </div>

                    <div className="offers-card-container">
                        <div className="offers-card">
                            <WebDevelopment color="#424242" style={{marginBottom: 10}} className="dev-icon"/>
                            <div className="title" style={{fontSize: 17, fontWeight: 700}}>{translate["WEB_DEVELOPMENT"]}</div>
                            <div className="frameworks-icon-container">
                                <ToopTip text="HTML, CSS, JS" extraLeft={2}>
                                    <HtmlCssJss color="#3caea3" style={{marginBottom: 5}} className="frameworks-icon first"/>
                                </ToopTip>
                                <ToopTip text="JQuery" extraLeft={17}>
                                    <JQuery color="#3caea3" style={{marginBottom: 5}} className="frameworks-icon second"/>
                                </ToopTip>
                                <ToopTip text="React.js" extraLeft={17}>
                                    <ReactImg color="#3caea3" style={{marginBottom: 5}} className="frameworks-icon third"/>
                                </ToopTip>
                                <ToopTip text=".NET MVC" extraLeft={17}>
                                    <DotNet color="#3caea3" style={{marginBottom: 5}} className="frameworks-icon fourth"/>
                                </ToopTip>
                                <ToopTip text="Node.js MVC" extraLeft={17}>
                                    <Node color="#3caea3" style={{marginBottom: 5}} className="frameworks-icon fifth"/>
                                </ToopTip>
                            </div>
                            <div className="offers-text">{translate["WEB_DEVELOPMENT_TEXT"]}</div>
                        </div>
                        <div className="offers-card">
                            <ServerDevelopment color="#424242" style={{marginBottom: 10}} className="dev-icon"/>
                            <div className="title" style={{fontSize: 17, fontWeight: 700}}>{translate["SERVER_DEVELOPMENT"]}</div>
                            <div className="frameworks-icon-container">
                                <ToopTip text=".NET" extraLeft={2}>
                                    <DotNet color="#3caea3" style={{marginBottom: 5}} className="frameworks-icon first"/>
                                </ToopTip>
                                <ToopTip text="Node.js" extraLeft={17}>
                                    <Node color="#3caea3" style={{marginBottom: 5}} className="frameworks-icon second"/>
                                </ToopTip>
                            </div>
                            <div className="offers-text">{translate["SERVER_DEVELOPMENT_TEXT"]}</div>
                        </div>
                        <div className="offers-card">
                            <GameDevelopment color="#424242" style={{marginBottom: 10}} className="dev-icon"/>
                            <div className="title" style={{fontSize: 17, fontWeight: 700}}>{translate["GAME_DEVELOPMENT"]}</div>
                            <div className="frameworks-icon-container">
                                <ToopTip text="Unity" extraLeft={4}>
                                    <Unity color="#3caea3" style={{marginBottom: 5}} className="frameworks-icon first"/>
                                </ToopTip>
                                <ToopTip text="Godot" extraLeft={18}>
                                    <Godot color="#3caea3" style={{marginBottom: 5}} className="frameworks-icon second"/>
                                </ToopTip>
                            </div>
                            <div className="offers-text">{translate["GAME_DEVELOPMENT_TEXT"]}</div>
                        </div>
                        <div className="offers-card">
                            <DesktopDevelopment color="#424242" style={{marginBottom: 10}} className="dev-icon"/>
                            <div className="title" style={{fontSize: 17, fontWeight: 700}}>{translate["DESKTOP_DEVELOPMENT"]}</div>
                            <div className="frameworks-icon-container">
                                <ToopTip text=".NET Windows Forms" width={170} extraLeft={-7}>
                                    <DotNet color="#3caea3" style={{marginBottom: 5}} className="frameworks-icon first"/>
                                </ToopTip>
                                <ToopTip text="Electron" extraLeft={18}>
                                    <Electron color="#3caea3" style={{marginBottom: 5}} className="frameworks-icon second"/>
                                </ToopTip>
                            </div>
                            <div className="offers-text">{translate["DESKTOP_DEVELOPMENT_TEXT"]}</div>
                        </div>
                        <div className="offers-card">
                            <AppDevelopment color="#424242" style={{marginBottom: 10}} className="dev-icon"/>
                            <div className="title" style={{fontSize: 17, fontWeight: 700}}>{translate["MOBILE_DEVELOPMENT"]}</div>
                            <div className="frameworks-icon-container">
                                <ToopTip text="React Native" extraLeft={2}>
                                    <ReactImg color="#3caea3" style={{marginBottom: 5}} className="frameworks-icon first"/>
                                </ToopTip>
                            </div>
                            <div className="offers-text">{translate["MOBILE_DEVELOPMENT_TEXT"]}</div>
                        </div>
                        {/* <div className="offers-card">
                            <ArtDevelopment color="#424242" style={{marginBottom: 10}} className="dev-icon"/>
                            <div className="title" style={{fontSize: 17, fontWeight: 700}}>{translate["ART_ILLUSTRATION"]}</div>
                            <div className="frameworks-icon-container">
                                <ToopTip text="Aseprite">
                                    <Aseprite color="#3caea3" style={{marginBottom: 5, width: 25}} className="frameworks-icon first"/>
                                </ToopTip>
                            </div>
                            <div className="offers-text">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellat voluptates ea ab eaque libero commodi recusandae quidem ex deserunt dolores.</div>
                        </div> */}
                    </div>
                </div>
            </div>

            {/* <div style={{width: "100%", height: 250, letterSpacing: 1, backgroundColor: "#424242", display: "flex", justifyContent: "center", alignItems: "center", fontSize: 25, color: "#f9f9f9", padding: "0 50px", boxSizing: "border-box"}}> */}
            <div ref={countersRef} className="counter-container-section">

                    {/* <FunnyCounter image={<CounterHours className="hours" color="#e0e0e0" />} progress={(Math.ceil(Math.abs(new Date() - new Date('09/20/2016')) / (1000 * 60 * 60 * 24))) * 8} step={197} slowDownStart={6000} slowDown={1000} slowDownStep={25} text={translate["HOURS"]} finalValue={(Math.ceil(Math.abs(new Date() - new Date('09/20/2016')) / (1000 * 60 * 60 * 24))) * 8 + "+"} animate={animations.funny_counters} /> */}
                    <FunnyCounter image={<CounterHours className="hours" color="#e0e0e0" />} progress={hoursOfWork} step={Math.floor(hoursOfWork / 20)} slowDownStart={hoursOfWork / 2.5} slowDown={hoursOfWork / 13} slowDownStep={Math.floor(hoursOfWork / 157)} text={translate["HOURS"]} finalValue={`${hoursOfWork}+`} animate={animations.funny_counters} />
                    <FunnyCounter image={<CounterLinesOfCode className="lines" color="#e0e0e0" />} progress={85923} step={1337} text={translate["LINESOFCODE"]} finalValue={translate["ALOT"]} slowDownStart={60000} slowDown={3000} slowDownStep={131} animate={animations.funny_counters} />
                    <FunnyCounter image={<CounterCoffees className="coffees" color="#e0e0e0" />} progress={537} step={12} text={translate["COFFEES"]} slowDownStart={340} slowDown={18} slowDownStep={1} animate={animations.funny_counters} />
                    
                    {/* <div className="quote fs-italic bold" dangerouslySetInnerHTML={{__html: translate["INSPIRING_PHRASE"]}}>
                    </div> */}
            </div>

            <div ref={portfolioRef} className="text-align-center position-relative" style={{padding: "100px 0", backgroundColor: "#f3f3f3"}}>
                <div className="title bold" style={{marginBottom: 70}}>
                    {translate["PORTFOLIO"]}
                </div>
                {/* <div className="porfolio-menu">
                    {porfolioMenuItems.map((mi, i) => 
                        <div key={i} className={`porfolio-item${porfolioMenuItem === mi.id ? " selected" : ""}`} onClick={() => setPorfolioMenuItem(mi.id)}>
                            {mi.name}
                        </div>
                    )}
                </div> */}
                <Gallery mobileMode={screenSize[0] <= 850} lang={lang} animate={animations.portfolio} list={porfolioData} columns={galleryCols} />
                {/* <div className="portfolio">
                    {animations.portfolio && porfolioData.filter(e => porfolioMenuItem === WorkType.All || e.types.indexOf(porfolioMenuItem) !== -1).map((pi, i) => 
                        <div key={i}>
                            <img key={`i-${i}`} alt="" src={pi.image} />
                            <div key={`gl-${i}`} className="grey-layer">
                                <div key={`t-${i}`} className="title">
                                    {pi.title}
                                </div>
                                <div key={`d-${i}`} className="text">
                                    {pi.description}
                                </div>
                                <div className="badges-container">
                                    <div key={`mr-${i}`} className="my-roles">
                                        {translate["MY_ROLES"]}:
                                    </div>
                                    {pi.types.map((b, i) => <div key={`b-${i}`} className={`badge badge-type-${b}`}>
                                        {
                                            b === WorkType.DesktopApp ? translate["DESKTOP_APP"] :
                                            b === WorkType.Website ? translate["WEBSITE"] :
                                            b === WorkType.MobileApp ? translate["MOBILE_APP"] :
                                            b === WorkType.Game ? translate["GAME"] :
                                            b === WorkType.PixelArt ? translate["ART"] :
                                            b === WorkType.Server ? translate["SERVER"] : ""
                                        }
                                    </div>)}
                                </div>
                                <a key={`l-${i}`} style={{marginTop: 50}} className="link" target="_blank" rel="noopener noreferrer" href={pi.link}>{translate["LINK_TO_WEBSITE"]}</a>
                            </div>
                        </div>
                    )}
                </div> */}
            </div>

            {/* <div style={{position:"absolute", width: "120%", height: 500, backgroundColor: "#f9f9f9", transform: "rotate(-5deg)"}}></div> */}

            <div ref={contactsRef} className="text-align-center position-relative" style={{padding: "100px 15px", display: "flex", alignItems: "center", flexDirection: "column"}}>
                <div className="title bold" style={{marginBottom: 70}}>
                    {translate["CONTACTS"]}
                </div>
                <div className="contacts-card-container">
                    <div className="contacts-card">
                        <ContactHome color="#424242" style={{marginBottom: 10}} className="contacts-icon"/>
                        <div className="title" style={{fontSize: 17, fontWeight: 700}}>{translate["LOCATION"]}</div>
                        <div className="offers-text">Loulé, Quarteira, Portugal</div>
                    </div>
                    <div className="contacts-card">
                        <ContactPhone color="#424242" style={{marginBottom: 10}} className="contacts-icon"/>
                        <div className="title" style={{fontSize: 17, fontWeight: 700}}>{translate["PHONE"]}</div>
                        <div className="offers-text">(+351) 969 706 066</div>
                    </div>
                    <div className="contacts-card">
                        <ContactEmail color="#424242" style={{marginBottom: 10}} className="contacts-icon"/>
                        <div className="title" style={{fontSize: 17, fontWeight: 700}}>Email</div>
                        <div className="offers-text">davidserodio@hotmail.com</div>
                    </div>
                </div>
                <div className={"form" + (contactOpened ? " open" : "")}>
                    <div className={"button-idle" + (contactOpened ? " open" : "")}></div>
                    <div className={"button title no-select" + (contactOpened ? " open" : "")} onClick={() => {if (!contactOpened) setContactOpened(true)}}>
                    {translate["CONTACT_ME"]}
                    </div>
                    <div style={{position: "relative"}} className={"submission-form" + (contactOpened ? " open" : "")}>
                        <input className={formErrors.name ? "error" : ""} ref={formNameRef} type="text" name="Name" placeholder={translate["NAME"]} />
                        <input className={formErrors.email ? "error" : ""} ref={formEmailRef} type="text" name="Email" placeholder="Email" />
                        <input className={formErrors.subject ? "error" : ""} ref={formSubjectRef} type="text" name="Subject" placeholder={translate["SUBJECT"]} />
                        <textarea className={formErrors.message ? "error" : ""} ref={formMessageRef} name="Message" placeholder={translate["MESSAGE"]}></textarea>
                        <SubmitButton validateForm={validateForm} onClick={sendEmail} />
                    </div>
                </div>
            </div>
            
            <div className="text-align-center position-relative footer">
                <div className="social-media-container">
                    <a href="https://www.facebook.com/davidserodiio" target="_blank" rel="noopener noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 167.657 167.657" >
                            <path d="M83.829,0.349C37.532,0.349,0,37.881,0,84.178c0,41.523,30.222,75.911,69.848,82.57v-65.081H49.626   v-23.42h20.222V60.978c0-20.037,12.238-30.956,30.115-30.956c8.562,0,15.92,0.638,18.056,0.919v20.944l-12.399,0.006   c-9.72,0-11.594,4.618-11.594,11.397v14.947h23.193l-3.025,23.42H94.026v65.653c41.476-5.048,73.631-40.312,73.631-83.154   C167.657,37.881,130.125,0.349,83.829,0.349z"/>
                        </svg>
                    </a>
                    <a href="https://www.linkedin.com/in/davidserodio/" target="_blank" rel="noopener noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" height="40px" width="40px" viewBox="0 0 512 512">
                            <path d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm-74.390625 387h-62.347656v-187.574219h62.347656zm-31.171875-213.1875h-.40625c-20.921875 0-34.453125-14.402344-34.453125-32.402344 0-18.40625 13.945313-32.410156 35.273437-32.410156 21.328126 0 34.453126 14.003906 34.859376 32.410156 0 18-13.53125 32.402344-35.273438 32.402344zm255.984375 213.1875h-62.339844v-100.347656c0-25.21875-9.027343-42.417969-31.585937-42.417969-17.222656 0-27.480469 11.601563-31.988282 22.800781-1.648437 4.007813-2.050781 9.609375-2.050781 15.214844v104.75h-62.34375s.816407-169.976562 0-187.574219h62.34375v26.558594c8.285157-12.78125 23.109375-30.960937 56.1875-30.960937 41.019531 0 71.777344 26.808593 71.777344 84.421874zm0 0"/>
                        </svg>
                    </a>

                    <a href="https://www.instagram.com/davidserodiio/" target="_blank" rel="noopener noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 512 512" >
                            <path d="m305 256c0 27.0625-21.9375 49-49 49s-49-21.9375-49-49 21.9375-49 49-49 49 21.9375 49 49zm0 0"/>
                            <path d="m370.59375 169.304688c-2.355469-6.382813-6.113281-12.160157-10.996094-16.902344-4.742187-4.882813-10.515625-8.640625-16.902344-10.996094-5.179687-2.011719-12.960937-4.40625-27.292968-5.058594-15.503906-.707031-20.152344-.859375-59.402344-.859375-39.253906 0-43.902344.148438-59.402344.855469-14.332031.65625-22.117187 3.050781-27.292968 5.0625-6.386719 2.355469-12.164063 6.113281-16.902344 10.996094-4.882813 4.742187-8.640625 10.515625-11 16.902344-2.011719 5.179687-4.40625 12.964843-5.058594 27.296874-.707031 15.5-.859375 20.148438-.859375 59.402344 0 39.25.152344 43.898438.859375 59.402344.652344 14.332031 3.046875 22.113281 5.058594 27.292969 2.359375 6.386719 6.113281 12.160156 10.996094 16.902343 4.742187 4.882813 10.515624 8.640626 16.902343 10.996094 5.179688 2.015625 12.964844 4.410156 27.296875 5.0625 15.5.707032 20.144532.855469 59.398438.855469 39.257812 0 43.90625-.148437 59.402344-.855469 14.332031-.652344 22.117187-3.046875 27.296874-5.0625 12.820313-4.945312 22.953126-15.078125 27.898438-27.898437 2.011719-5.179688 4.40625-12.960938 5.0625-27.292969.707031-15.503906.855469-20.152344.855469-59.402344 0-39.253906-.148438-43.902344-.855469-59.402344-.652344-14.332031-3.046875-22.117187-5.0625-27.296874zm-114.59375 162.179687c-41.691406 0-75.488281-33.792969-75.488281-75.484375s33.796875-75.484375 75.488281-75.484375c41.6875 0 75.484375 33.792969 75.484375 75.484375s-33.796875 75.484375-75.484375 75.484375zm78.46875-136.3125c-9.742188 0-17.640625-7.898437-17.640625-17.640625s7.898437-17.640625 17.640625-17.640625 17.640625 7.898437 17.640625 17.640625c-.003906 9.742188-7.898437 17.640625-17.640625 17.640625zm0 0"/>
                            <path d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm146.113281 316.605469c-.710937 15.648437-3.199219 26.332031-6.832031 35.683593-7.636719 19.746094-23.246094 35.355469-42.992188 42.992188-9.347656 3.632812-20.035156 6.117188-35.679687 6.832031-15.675781.714844-20.683594.886719-60.605469.886719-39.925781 0-44.929687-.171875-60.609375-.886719-15.644531-.714843-26.332031-3.199219-35.679687-6.832031-9.8125-3.691406-18.695313-9.476562-26.039063-16.957031-7.476562-7.339844-13.261719-16.226563-16.953125-26.035157-3.632812-9.347656-6.121094-20.035156-6.832031-35.679687-.722656-15.679687-.890625-20.6875-.890625-60.609375s.167969-44.929688.886719-60.605469c.710937-15.648437 3.195312-26.332031 6.828125-35.683593 3.691406-9.808594 9.480468-18.695313 16.960937-26.035157 7.339844-7.480469 16.226563-13.265625 26.035157-16.957031 9.351562-3.632812 20.035156-6.117188 35.683593-6.832031 15.675781-.714844 20.683594-.886719 60.605469-.886719s44.929688.171875 60.605469.890625c15.648437.710937 26.332031 3.195313 35.683593 6.824219 9.808594 3.691406 18.695313 9.480468 26.039063 16.960937 7.476563 7.34375 13.265625 16.226563 16.953125 26.035157 3.636719 9.351562 6.121094 20.035156 6.835938 35.683593.714843 15.675781.882812 20.683594.882812 60.605469s-.167969 44.929688-.886719 60.605469zm0 0"/>
                        </svg>
                    </a>
                </div>
                <div className="languages-container">
                    <ButtonSwitch options={["en", "pt"]} value={lang} onClick={(changedLanguage) => setLanguage(changedLanguage)} />
                </div>

            </div>

            {/* <AboutMe /> */}
            {/* <FullScreenArea extraHeight={120} className="primary-background"> */}
                {/* <Header reff={header1} onClick={itmClick} selectedItem={selectedItem} /> */}
                {/* <FloatingArea /> */}
                {/* <div style={{marginTop: -80}} className="dave-area vertical-center text-align-center position-relative w100">
                    <img alt="" src={imagemoldure} />
                    <div className="title bold" style={{fontSize: 44, color: '#e1e1e1'}} >David Serôdio</div>
                    <div className="title bold secondary-color" style={{fontSize: 18}} >Full stack developer</div>
                </div> */}
                {/* <BouncingMouse style={{color: '#b1b1b1', bottom: 135}} /> */}
                {/* <Pattern /> */}
            {/* </FullScreenArea> */}
            {/* <AboutMe /> */}
            {/* <FullScreenArea>
                <Header reff={header2} onClick={itmClick} selectedItem={selectedItem} className="v2" />
                <SplitArea left={AboutMe} right={AboutMeContent} />
            </FullScreenArea>
            <FullScreenArea>
                <Header reff={header3} onClick={itmClick} selectedItem={selectedItem} />
                <SplitArea left={MyOffers} right={MyOffersContent} />
            </FullScreenArea>
            <FullScreenArea>
                <Header reff={header4} onClick={itmClick} selectedItem={selectedItem} className="v2" />
                <SplitArea left={AboutMe} right={AboutMeContent} />
            </FullScreenArea> */}

            

        </FullPageContainer>
    );
};

// const Page = () => {

//     const [selectedItem, setSelectedItem] = useState(1);

//     setTimeout(() => {
//         setSelectedItem(2);
//     }, 5000);

//     return (
//         <React.Fragment>
//             <FullScreenArea className="primary-background">
//                 <Header selectedItem={selectedItem} />
//                 <FloatingArea />
//                 <div className="dave-area vertical-center text-align-center position-relative w100">
//                     <img alt="" src={imagemoldure} />
//                     <div className="title bold" style={{fontSize: 44, color: '#e1e1e1'}} >David Serôdio</div>
//                     <div className="title bold secondary-color" style={{fontSize: 18}} >Full stack developer</div>
//                 </div>
//                 <BouncingMouse style={{color: '#b1b1b1'}} />
//             </FullScreenArea>
//             <FullScreenArea>
//                 <Header selectedItem={selectedItem} className="v2" />
//                 <SplitArea left={AboutMe} right={AboutMeContent} />
//             </FullScreenArea>
//         </React.Fragment>
//     );
// };

// const MemorizedPage = React.memo(Page);

export default React.memo(FullPage);