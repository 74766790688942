import React from 'react';
import FullPage from './containers/FullPage';
import './App.css';

function App() {

  return (
    <FullPage />
  );
}

export default App;
