import React from 'react';

const Loading = ({color, ...rest}) => (
    <svg version="1.1" id="L5" x="0px" y="0px"
        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" space="preserve" {...rest}>
        <circle fill={color} stroke="none" cx="26" cy="57" r="8">
            <animateTransform 
            attributeName="transform" 
            dur=".8s"
            type="translate" 
            values="0 0; 0 -14; 0 0" 
            repeatCount="indefinite" 
            begin="0"/>
        </circle>
        <circle fill={color} stroke="none" cx="50" cy="57" r="8">
            <animateTransform 
            attributeName="transform" 
            dur=".8s"
            type="translate" 
            values="0 0; 0 -14; 0 0" 
            repeatCount="indefinite" 
            begin="0.2"/>
        </circle>
        <circle fill={color} stroke="none" cx="74" cy="57" r="8">
            <animateTransform 
            attributeName="transform" 
            dur=".8s"
            type="translate" 
            values="0 0; 0 -14; 0 0" 
            repeatCount="indefinite" 
            begin="0.4"/>
        </circle>
    </svg>
);

export default React.memo(Loading);