import React from 'react';


const FullScreenArea = React.memo(({extraHeight, height, width, style, className, ...props}) => {
    const heightt = height || (extraHeight ? `calc(100% + ${extraHeight}px)` : '100%');
    const widthh = width || '100%';

    return <div className={"full-screen-area" + (className ? ` ${className}` : "")} style={{...style, ...{width: widthh, height: heightt, position: 'relative', overflow: 'hidden'}}} {...props}>
        {props.children}
    </div>
});

export default React.memo(FullScreenArea);