import React, {useState} from 'react';
import {MenuTab} from '../assets/enums.js';
import AvailableLanguages from '../assets/language';
import { HamburguerMenu } from './HamburguerMenu.jsx';

export const Menu = React.memo(({tab, lang, hamburguerMode, menuClick, focused, ...rest}) => {

    const [open, setOpen] = useState(false);
    const translate = AvailableLanguages[lang];

    const menuItemClick = (tab) => {
        menuClick(tab);
        if (hamburguerMode && open) setOpen(false);
    }

    return (
        <div className={`menu${focused ? " focus" : ""}${hamburguerMode ? " hamburguer-mode" : ""}${hamburguerMode && open ? " open" : ""}`}>
            {hamburguerMode && <HamburguerMenu open={open} click={() => setOpen(!open)} />}
            <div onClick={() => menuItemClick(MenuTab.Home)} className={`menu-item no-select${tab === MenuTab.Home ? " selected" : "" }`}>
                {translate["HOME"]}
            </div>
            <div onClick={() => menuItemClick(MenuTab.AboutMe)} className={`menu-item no-select${tab === MenuTab.AboutMe ? " selected" : "" }`}>
                {translate["ABOUT_ME"]}
            </div>
            <div onClick={() => menuItemClick(MenuTab.LanguageSkills)} className={`menu-item no-select${tab === MenuTab.LanguageSkills ? " selected" : "" }`}>
                {translate["SKILLS"]}
            </div>
            <div onClick={() => menuItemClick(MenuTab.MyTimeline)} className={`menu-item no-select${tab === MenuTab.MyTimeline ? " selected" : "" }`}>
                {translate["MY_TIMELINE"]}
            </div>
            <div onClick={() => menuItemClick(MenuTab.MyOffers)} className={`menu-item no-select${tab === MenuTab.MyOffers ? " selected" : "" }`}>
                {translate["MY_OFFERS"]}
            </div>
            <div onClick={() => menuItemClick(MenuTab.Portfolio)} className={`menu-item no-select${tab === MenuTab.Portfolio ? " selected" : "" }`}>
                {translate["PORTFOLIO"]}
            </div>
            <div onClick={() => menuItemClick(MenuTab.Contacts)} className={`menu-item no-select${tab === MenuTab.Contacts ? " selected" : "" }`}>
                {translate["CONTACTS"]}
            </div>
        </div>
    );
});