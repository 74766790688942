export default {
    "HELLO_IM": "Olá, eu sou o",
    "FULLSTACK_DEVELOPER": "Programador fullstack",
    "GAME_DEVELOPER": "Programador de jogos",
    "3D_MODELER": "modelador 3D",
    "IAMA": "E sou um ",
    "ABOUT_ME": "Sobre mim",
    "ABOUT_ME_TEXT": "Como Programador Senior com {YEARS} anos de experiência em diversas áreas de desenvolvimento, sou reconhecido pela minha criatividade, comprometimento e forte senso de responsabilidade.<br />A minha habilidade de me destacar em ambientes de equipa, juntamente com minha fluência em português e habilidades avançadas em inglês, permitem-me colaborar e contribuir eficazmente em ambientes de trabalho diversificados.<br />Estou ansioso para enfrentar desafios que promovam o crescimento profissional e me permitam utilizar a minha expertise para alcançar resultados concretos.",
    // "ABOUT_ME_TEXT": "Programador sénior, com {YEARS} anos de experiência em várias áreas de desenvolvimento. <br />Empenhado, criativo, responsável e bom trabalhador em equipa. <br />Fluente na língua portuguesa e avançado na inglesa. <br />Procuro desafios que me permitam evoluir profissionalmente.",
    "DOWNLOAD_CV": "Descarregar CV",
    "DESCRIPTION": "Descrição",
    "TOOLS": "Ferramentas",
    "WHAT_I_CAN_OFFER": "O que posso oferecer",
    "WEB_DEVELOPMENT": "Desenvolvimento de sites",
    "SERVER_DEVELOPMENT": "Desenvolvimento para servidores",
    "GAME_DEVELOPMENT": "Desenvolvimento de jogos",
    "DESKTOP_DEVELOPMENT": "Desenvolvimento para computador",
    "MOBILE_DEVELOPMENT": "Desenvolvimento para telemóvel",
    "MY_TIMELINE": "Linha do tempo",
    "HOBBY": "Passatempo",
    "PROFESSIONAL": "Profissional",
    "WE_ARE_HERE": "Estamos aqui",
    "UNTIL_NOW": "Até agora",
    // "INSPIRING_PHRASE": "“Qualquer tolo pode escrever código que um computador possa entender. <br />Bons programadores escrevem código que os humanos podem entender.” <div class=\"fs-normal\" style=\"color: #3caea3; font-size: 18px; text-align: right; margin-top: 25px\">― Martin Fowler</div>",
    "PORTFOLIO": "Portfólio",
    "ALL": "Tudo",
    "WEBSITES": "Sites",
    "GAMES": "Jogos",
    "MOBILE_APPS": "Apps Telemóvel",
    "DESKTOP_APPS": "Apps Computador",
    "ARTS": "Artes",
    "FRONTEND": "Frontend",
    "FRONTEND_MAINTAINER": "Frontend Maintainer",
    "BACKEND_MAINTAINER": "Backend Maintainer",
    "BACKEND": "Backend",
    "WIDGET": "Widget",
    "API": "API",
    "DATABASE": "Base de dados",
    "APP": "Aplicação",
    "GAME": "Jogo",
    "MOBILE_APP": "App Telemóvel",
    "DESKTOP_APP": "App Computador",
    "ART": "Arte",
    "MY_ROLES": "Funções desempenhadas",
    "LINK_TO_WEBSITE": "Link para o site",
    "CONTACTS": "Contactos",
    "CONTACT_ME": "Contacte-me",
    "SUBMIT": "Submeter",
    "LOCATION": "Localização",
    "PHONE": "Telemóvel",
    "NAME": "Nome",
    "SUBJECT": "Assunto",
    "MESSAGE": "Mensagem",
    "WATCHVIDEO": "Ver vídeo",
    "ALOT": "Muitas",

    "HOME": "Início",
    "SKILLS": "Competências",
    "MY_OFFERS": "Ofertas",

    "AIRDESK_DESCRIPTION": "Plataforma Tudo-em-Um de colaboração e gestão de empresas.",
    "MANUAISESCOLARES_DESCRIPTION": "Plataforma que fornece vouchers grátis de manuais para os alunos.",
    "LOYALTY_DESCRIPTION": "Plataforma de comércio eletrônico para troca de pontos por serviços ou produtos.",
    "GETCOMPLIED_DESCRIPTION": "Widget para websites que ajuda a cumprir as regras do RGPD da UE.",
    "BRAINIACCHESS_DESCRIPTION": "Jogo online de xadrez com apostas de crypto moeda e torneios.",
    "SMARTCITY_DESCRIPTION": "Plataforma para reportar problemas nas cidades com a finalidade de serem reparados.",
    "WINESOFPORTUGAL_DESCRIPTION": "Plataforma para divulgação dos vinhos portugueses pelo mundo.",
    "POUPAENERGIA_DESCRIPTION": "Plataforma de simulação e comparação de tarifários de eletricidade e gás natural.",
    "RIADEAVEIRO_DESCRIPTION": "Plataforma que permite ao utilizador conhecer a ria de Aveiro e fornece percursos e actividades.",
    "GEOPARQUE_DESCRIPTION": "Plataforma que permite ao utilizador conhecer o geoparque de Aveiro e fornece percursos com vertente geológica.",
    "PLANNER_DESCRIPTION": "Componente customizável para websites que serve para planear percursos.",
    "CAMPING_DESCRIPTION": "Backoffice de plataforma que dá a conhecer a rede de parques de campismo e caravanismo e permite fazer reservas.",
    "GEOPARQUETABLE_DESCRIPTION": "Plataforma que permite ao utilizador conhecer o geoparque de Aveiro e fornece percursos com vertente geológica. (Versão kiosk)",
    "CASAMAIS_DESCRIPTION": "Plataforma que ajuda a identificar as oportunidades de melhoria a implementar nos imóveis para reduzir os consumos de energia e água.",
    "MULTIWASHER_DESCRIPTION": "Plataforma que gera flyers em PDF consoante os dados introduzidos em backoffice.",
    "TIMOR_DESCRIPTION": "Plataforma de segurança social de Timor-Leste.",
    "WHISTLEBLOWER_DESCRIPTION": "Plataforma para fazer denúncias de forma anónima.",
    "WHISTLEBLOWER_BACKOFFICE_DESCRIPTION": "Plataforma para fazer denúncias de forma anónima. (Backend)",
    "OGPREDICT_DESCRIPTION": "Plataforma de apostas desportivas de futebol que lhe dá a oportunidade de ganhar recompensas pelas suas habilidades de previsão.",
    "CCV_BACKEND_DESCRIPTION": "Plataforma de gestão dos correios de Cabo Verde. (Backend)",
    "CCV_APP_DESCRIPTION": "Plataforma de gestão dos correios de Cabo Verde. (Desktop App)",
    "INTELCLUE_BACKOFFICE_DESCRIPTION": "Solução empresarial para proteger e encriptar arquivos e dados. (Backend)",
    "INTELCLUE_OUTLOOK_DESCRIPTION": "Solução empresarial para proteger e encriptar arquivos e dados. (Outlook plugin)",
    "INSS_STRAPI_DESCRIPTION": "Portal da Segurança Social de Timor-Leste. (CMS Backoffice - Strapi)",
    "HARDSECURE_DESCRIPTION": "Plataforma de apresentação dos serviços da empresa.",
    "HARDSECURE_STRAPI_DESCRIPTION": "Plataforma de apresentação dos serviços da empresa. (CMS Backoffice - Strapi)",

    "WEB_DEVELOPMENT_TEXT": "Sites personalizados, interativos, dinâmicos, responsivos, de fácil manutenção e únicos que vão de encontro às suas necessidades.",
    "SERVER_DEVELOPMENT_TEXT": "APIs rápidas e seguras, tanto para jogos com comunicação em tempo real como para websites e aplicações.",
    "GAME_DEVELOPMENT_TEXT": "Jogos de todos os géneros, 2D ou 3D, funcionais para qualquer plataforma.",
    "DESKTOP_DEVELOPMENT_TEXT": "Aplicações e serviços para Windows, Mac e Linux.",
    "MOBILE_DEVELOPMENT_TEXT": "Aplicações para iOS e Android com bom desempenho.",

    "HOURS": "Horas",
    "LINESOFCODE": "Linhas de código",
    "COFFEES": "Cafés",





    "HOBBY_1_TITLE": "Jogo 2D (Unity)",
    "HOBBY_1_TEXT": "Jogo de plataforma 2D para testar a tecnologia.",

    "HOBBY_2_TITLE": "Site de Músicas",
    "HOBBY_2_TEXT": "Site para ouvir e gerir músicas locais no computador com controlos básicos (aleatório, reproduzir, meter em pausa, volume, avançar segundos, próximo).",

    "HOBBY_3_TITLE": "Câmeras de Segurança",
    "HOBBY_3_TEXT": "Construção de uma câmera com Raspberry Pi, software e servidor de streaming.",

    "HOBBY_4_TITLE": "Jogo 2D (Godot)",
    "HOBBY_4_TEXT": "Jogo indie de plataforma em estilo de pixel.",
    
    "HOBBY_5_TITLE": "Jogo de Xadrez",
    "HOBBY_5_TEXT": "Jogo online de xadrez com apostas de crypto moeda e torneios - Radical Chess.",
    
    "HOBBY_6_TITLE": "Anglo Dinero",
    "HOBBY_6_TEXT": "Plataforma de aprendizagem baseada em NFT que permite que aos utilizadores ganhem recompensas enquanto aprendem.",
    
    "HOBBY_7_TITLE": "OG Predict",
    "HOBBY_7_TEXT": "Plataforma de apostas desportivas de futebol que lhe dá a oportunidade de ganhar recompensas pelas suas habilidades de previsão.",

    "PROFESSIONAL_1_TITLE": "Escola Profissional de Alte",
    "PROFESSIONAL_1_TEXT": "Curso Profissional de Informática de Gestão - Ensino Secundário.",
    
    "PROFESSIONAL_2_TITLE": "Programador Fullstack",
    "PROFESSIONAL_2_TEXT": "Programador na empresa Algardata S.A a desenvolver sites, base de dados, aplicações de computador e APIs.",
    
    "PROFESSIONAL_3_TITLE": "Programador de Front-End",
    "PROFESSIONAL_3_TEXT": "Programador na empresa Ubiwhere a desenvolver sites.",
    
    "PROFESSIONAL_4_TITLE": "Programador Líder Fullstack",
    "PROFESSIONAL_4_TEXT": "Programador na empresa Linkwithus a desenvolver sites, base de dados, aplicações de computador, aplicações de telemóvel e APIs.",
    
    "PROFESSIONAL_5_TITLE": "Programador Líder Fullstack",
    "PROFESSIONAL_5_TEXT": "Programador na empresa PwC Portugal a desenvolver sites, base de dados, aplicações de computador, aplicações de telemóvel e APIs.",
}