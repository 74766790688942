import React, { useState, useEffect, useRef, useMemo } from "react";
import AvailableLanguages from "../assets/language";

const Timeline = ({ animate, lang, smallMode, screenWidth, ...props }) => {
  const [data, setData] = useState({ dict: {}, years: [] });
  const timelineEl = useRef(null);
  const translate = useMemo(() => AvailableLanguages[lang], [lang]);
  const [smallModeSide, setSmallModeSide] = useState(1);
  const [firstAnimationsDone, setFirstAnimationsDone] = useState(false);

  // shadowColor é 40% opacity da cor original
  const hobby = useMemo(
    () => [
      {
        begin: 2016,
        end: 2016,
        title: translate["HOBBY_1_TITLE"],
        text: translate["HOBBY_1_TEXT"],
        color: "#41B852",
        shadowColor: "#afdfb6",
      },
      {
        begin: 2017,
        end: 2017,
        title: translate["HOBBY_2_TITLE"],
        text: translate["HOBBY_2_TEXT"],
        color: "#3c9bec",
        shadowColor: "#add3f3",
      },
      {
        begin: 2019,
        end: 2019,
        title: translate["HOBBY_3_TITLE"],
        text: translate["HOBBY_3_TEXT"],
        color: "#EC018C",
        shadowColor: "#f395cd",
      },
      {
        begin: 2020,
        end: 2020,
        title: translate["HOBBY_4_TITLE"],
        text: translate["HOBBY_4_TEXT"],
        color: "#F16521",
        shadowColor: "#f5bda2",
      },
      {
        begin: 2020,
        end: 2021,
        title: translate["HOBBY_5_TITLE"],
        text: translate["HOBBY_5_TEXT"],
        color: "#e4c13f",
        shadowColor: "#f0e2ae",
      },
      {
        begin: 2022,
        end: 2022,
        title: translate["HOBBY_6_TITLE"],
        text: translate["HOBBY_6_TEXT"],
        color: "#232a68",
        shadowColor: "#a3a6bf",
      },
      {
        begin: 2022,
        end: 2022,
        title: translate["HOBBY_7_TITLE"],
        text: translate["HOBBY_7_TEXT"],
        color: "#3d9956",
        shadowColor: "#add2b7",
      },
    ],
    [translate]
  );

  const professional = useMemo(
    () => [
      {
        begin: 2012,
        end: 2015,
        title: translate["PROFESSIONAL_1_TITLE"],
        text: translate["PROFESSIONAL_1_TEXT"],
        color: "#2483ad",
        shadowColor: "#b9d6e2",
      },
      {
        begin: 2016,
        end: 2021,
        title: translate["PROFESSIONAL_2_TITLE"],
        text: translate["PROFESSIONAL_2_TEXT"],
        color: "#325ea8",
        shadowColor: "#a9bbd8",
        endsAtBeginning: true,
      },
      {
        begin: 2021,
        end: 2021,
        title: translate["PROFESSIONAL_3_TITLE"],
        text: translate["PROFESSIONAL_3_TEXT"],
        color: "#855ad2",
        shadowColor: "#855ad2",
      },
      {
        begin: 2021,
        end: 2023,
        title: translate["PROFESSIONAL_4_TITLE"],
        text: translate["PROFESSIONAL_4_TEXT"],
        color: "#d91c1c",
        shadowColor: "#eca0a0",
      },
      {
        begin: 2023,
        end: null,
        title: translate["PROFESSIONAL_5_TITLE"],
        text: translate["PROFESSIONAL_5_TEXT"],
        color: "#4fc0c1",
        shadowColor: "#b5e2e2",
      },
    ],
    [translate]
  );

  const currentYear = useRef(new Date().getFullYear()).current;

  useEffect(() => {
    // if (animate)
    // {
    //     setTimeout(() => {
    //         timelineEl.current.scrollTop = 99999;
    //     }, 4000);
    //     return;
    // }

    setTimeout(() => {
      timelineEl.current.scrollTop = 99999;
    }, 0);

    var dict = {};
    var years = [];

    hobby.forEach((h) => {
      if (!dict[h.begin]) {
        dict[h.begin] = {
          Hobbies: [h],
          Professional: [],
        };
        years.push(h.begin);
      } else {
        dict[h.begin].Hobbies.push(h);
      }

      const end = h.end || currentYear;

      if (!dict[end]) {
        dict[end] = { Hobbies: [], Professional: [] };
        years.push(end);
      }
    });

    professional.forEach((p) => {
      if (!dict[p.begin]) {
        dict[p.begin] = {
          Hobbies: [],
          Professional: [p],
        };
        years.push(p.begin);
      } else {
        dict[p.begin].Professional.push(p);
      }

      const end = p.end || currentYear;

      if (!dict[end]) {
        dict[end] = { Hobbies: [], Professional: [] };
        years.push(end);
      }
    });

    if (!dict[currentYear]) {
      dict[currentYear] = { Hobbies: [], Professional: [] };
      years.push(currentYear);
    }

    years = years.sort();

    setData({ dict, years });
  }, [lang, currentYear, hobby, professional]);

  var height = 149;

  if (screenWidth <= 630) height += 54;

  var getItems = (initialYear, finalYear, fromIndex, endsAtBeginning) => {
    var items = 0;

    if (fromIndex !== undefined) items = -fromIndex;

    // if (initialYear === 2016 && finalYear === 2021) {
    //   debugger;
    // }
    for (const year of data.years) {
      if (
        (initialYear != null && initialYear > year) ||
        (finalYear != null && finalYear < year)
      )
        continue;

      if (endsAtBeginning && year === finalYear) {
        return items;
      }

      var hob = data.dict[year].Hobbies.length;
      var profs = data.dict[year].Professional.length;

      var highest = hob > profs ? hob : profs;

      items += highest || 1;
    }

    return items;
  };

  if (animate && !firstAnimationsDone) {
    setTimeout(() => {
      if (firstAnimationsDone) return;
      setFirstAnimationsDone(true);
    }, 3000);
  }

  const smallModeChangeSide = (mode) => {
    if (!smallMode || smallModeSide === mode) return;

    setSmallModeSide(mode);
  };

  // var totalitems = getItems();
  return (
    <div
      className={
        "timeline" +
        (animate ? " animate" : "") +
        (smallMode ? " small-mode" : "") +
        (smallModeSide === 0 ? " left" : " right") +
        (firstAnimationsDone ? " loaded-initial-anim" : "")
      }
      {...props}
    >
      <div className="title bold" style={{ marginBottom: 40 }}>
        <div
          className={
            "timeline-mode-button left" +
            (smallModeSide === 1 && smallMode ? " active" : "")
          }
          onClick={() => smallModeChangeSide(0)}
        >
          {translate["HOBBY"]}
        </div>
        {translate["MY_TIMELINE"]}
        <div
          className={
            "timeline-mode-button right" +
            (smallModeSide === 0 && smallMode ? " active" : "")
          }
          onClick={() => smallModeChangeSide(1)}
        >
          {translate["PROFESSIONAL"]}
        </div>
      </div>
      <div className="timeline-titles-container">
        <div className="timeline-titles">
          <div className="title bold">{translate["HOBBY"]}</div>
          <div className="title bold">{translate["PROFESSIONAL"]}</div>
        </div>
      </div>
      <div ref={timelineEl} className="timeline-scroller">
        <div
          className="timeline-columns-container no-select"
          onClick={() => smallModeChangeSide(smallModeSide === 0 ? 1 : 0)}
        >
          <div style={{ paddingTop: 30 }}>
            {data.years.map((y, i) => (
              <>
                {data.dict[y].Hobbies.length === 0 ? (
                  <>
                    {Array.from(
                      Array(data.dict[y].Professional.length || 1).keys()
                    ).map((mm, ii) => (
                      <div
                        className="timeline-card"
                        style={{ marginLeft: 30, visibility: "hidden" }}
                      ></div>
                    ))}
                  </>
                ) : (
                  data.dict[y].Hobbies.map((e, i2) => (
                    <>
                      <div
                        className={
                          "timeline-card left" +
                          ((e.end || currentYear) - e.begin > 0
                            ? " remove-borders"
                            : "")
                        }
                        style={{
                          zIndex: i + i2,
                          backgroundColor: "#f9f9f9",
                          marginRight: 30,
                          borderColor: e.color,
                        }}
                      >
                        <div
                          className="bold"
                          style={{ fontSize: 11, color: "#424242" }}
                        >
                          {e.begin} -{" "}
                          {e.end === null ? translate["UNTIL_NOW"] : e.end}
                        </div>
                        <div
                          className="bold"
                          style={{ fontSize: 15, color: e.color, marginTop: 5 }}
                        >
                          {e.title}
                        </div>
                        <div
                          style={{
                            fontSize: 13,
                            color: "#737373",
                            marginTop: 8,
                          }}
                        >
                          {e.text}
                        </div>
                      </div>
                      {data.years.indexOf(e.end || currentYear) -
                        data.years.indexOf(y) >
                        0 && (
                        <div
                          className="timeline-card left"
                          style={{
                            zIndex: i + i2,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            marginRight: 30,
                            marginTop: -19,
                            height:
                              (getItems(
                                e.begin,
                                e.end || currentYear,
                                i2,
                                e.endsAtBeginning
                              ) -
                                1) *
                                height +
                              19 -
                              15,
                            borderColor: e.color,
                            position: "absolute",
                            borderTopWidth: 0,
                            backgroundColor: `${e.shadowColor}`,
                          }}
                        ></div>
                      )}
                    </>
                  ))
                )}
              </>
            ))}
            {/* {hobby.length == 0 &&
                            <div style={{marginRight:30, width: 290, height: 0, border: "1px solid #3caea3", padding: 10, visibility: "hidden"}}>
                            </div>
                        } */}
          </div>
          <div className="timeline-line"></div>
          <div style={{ paddingTop: 30 }}>
            {data.years.map((y, i) => (
              <>
                {data.dict[y].Professional.length === 0 ? (
                  <>
                    {data.dict[y].Hobbies.length > 0 && (
                      <div
                        className="timeline-date-circle"
                        style={{ background: data.dict[y].Hobbies[0].color }}
                      ></div>
                    )}
                    <div className="timeline-date">{y}</div>
                    {/* {data.dict[y].Hobbies.length > 0 &&  */}
                    {Array.from(
                      Array(data.dict[y].Hobbies.length || 1).keys()
                    ).map((mm, ii) => (
                      <div
                        className="timeline-card"
                        style={{ marginLeft: 30, visibility: "hidden" }}
                      ></div>
                    ))}
                  </>
                ) : (
                  <>
                    <div
                      className="timeline-date-circle"
                      style={{
                        background:
                          data.dict[y].Hobbies.length === 0
                            ? data.dict[y].Professional[0].color
                            : `linear-gradient(to right, ${data.dict[y].Hobbies[0].color} 50%, ${data.dict[y].Professional[0].color} 50%)`,
                      }}
                    ></div>
                    <div className="timeline-date">{y}</div>
                    {data.dict[y].Professional.map((e, i2) => (
                      <>
                        <div
                          className={
                            "timeline-card right" +
                            ((e.end || currentYear) - e.begin > 0
                              ? " remove-borders"
                              : "")
                          }
                          style={{
                            zIndex: i + i2,
                            backgroundColor: "#f9f9f9",
                            marginLeft: 30,
                            borderColor: e.color,
                          }}
                        >
                          <div
                            className="bold"
                            style={{ fontSize: 11, color: "#424242" }}
                          >
                            {e.begin} -{" "}
                            {e.end === null ? translate["UNTIL_NOW"] : e.end}
                          </div>
                          <div
                            className="bold"
                            style={{
                              fontSize: 15,
                              color: e.color,
                              marginTop: 5,
                            }}
                          >
                            {e.title}
                          </div>
                          <div
                            style={{
                              fontSize: 13,
                              color: "#737373",
                              marginTop: 8,
                            }}
                          >
                            {e.text}
                          </div>
                        </div>
                        {data.years.indexOf(e.end || currentYear) -
                          data.years.indexOf(y) >
                          0 && (
                          <div
                            className="timeline-card right"
                            style={{
                              zIndex: i + i2,
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                              marginLeft: 30,
                              marginTop: -19,
                              height:
                                (getItems(
                                  e.begin,
                                  e.end || currentYear,
                                  i2,
                                  e.endsAtBeginning
                                ) -
                                  1) *
                                  height +
                                19 -
                                15,
                              borderColor: e.color,
                              position: "absolute",
                              borderTopWidth: 0,
                              backgroundColor: `${e.shadowColor}`,
                            }}
                          ></div>
                        )}
                      </>
                    ))}
                    {data.dict[y].Hobbies.length >
                      data.dict[y].Professional.length &&
                      Array.from(
                        Array(
                          data.dict[y].Hobbies.length -
                            data.dict[y].Professional.length
                        ).keys()
                      ).map((mm, ii) => (
                        <div
                          className="timeline-card"
                          style={{ marginLeft: 30, visibility: "hidden" }}
                        ></div>
                      ))}
                  </>
                )}
              </>
            ))}
          </div>
          <div className="bold we-are-here">{translate["WE_ARE_HERE"]}</div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Timeline, (prevProps, nextProps) => {
  return prevProps.animate === nextProps.animate &&
         prevProps.lang === nextProps.lang &&
         prevProps.smallMode === nextProps.smallMode &&
         prevProps.screenWidth === nextProps.screenWidth;
});
