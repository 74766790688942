import React from 'react';

const ToopTip = ({text, extraLeft, width, ...rest}) => {

  if (!extraLeft) extraLeft = 0;
  if (!width) width = 150;

  const left = -61 + extraLeft;

  return (
    <div className="tooltip-container">
      {rest.children}
      <div className="tooltip" style={{left, width}}>{text}</div>
    </div>
)};

export default React.memo(ToopTip);