export default {
    "HELLO_IM": "Hi, I'm",
    "FULLSTACK_DEVELOPER": "Fullstack developer",
    "GAME_DEVELOPER": "Game developer",
    "3D_MODELER": "3D modeler",
    "IAMA": "and I'm a ",
    "ABOUT_ME": "About me",
    "ABOUT_ME_TEXT": "As a Senior Developer with {YEARS} years of experience in various areas of development, I am recognized for my creativity, commitment, and strong sense of responsibility.<br />My ability to excel in team environments, along with my fluency in Portuguese and advanced English skills, allow me to collaborate and contribute effectively in diverse work environments.<br />I am eager to face challenges that promote professional growth and allow me to use my expertise to achieve concrete results.",
    // "ABOUT_ME_TEXT": "Senior developer, with {YEARS} years of experience in various areas of development. <br />I am committed, creative, responsible and a good team worker.<br />Fluent in Portuguese and advanced in English.<br />I look for challenges that allow me to evolve professionally.",
    "DOWNLOAD_CV": "Download CV",
    "DESCRIPTION": "Description",
    "TOOLS": "Tools",
    "WHAT_I_CAN_OFFER": "What I can offer",
    "WEB_DEVELOPMENT": "Web development",
    "SERVER_DEVELOPMENT": "Server development",
    "GAME_DEVELOPMENT": "Game development",
    "DESKTOP_DEVELOPMENT": "Computer development",
    "MOBILE_DEVELOPMENT": "Mobile development",
    "MY_TIMELINE": "Timeline",
    "HOBBY": "Hobby",
    "PROFESSIONAL": "Professional",
    "WE_ARE_HERE": "We are here",
    "UNTIL_NOW": "Until now",
    // "INSPIRING_PHRASE": "“Qualquer tolo pode escrever código que um computador possa entender. <br />Bons programadores escrevem código que os humanos podem entender.” <div class=\"fs-normal\" style=\"color: #3caea3; font-size: 18px; text-align: right; margin-top: 25px\">― Martin Fowler</div>",
    "PORTFOLIO": "Portfolio",
    "ALL": "All",
    "WEBSITES": "Websites",
    "GAMES": "Games",
    "MOBILE_APPS": "Mobile Apps",
    "DESKTOP_APPS": "Computer Apps",
    "ARTS": "Arts",
    "FRONTEND": "Frontend",
    "FRONTEND_MAINTAINER": "Frontend Maintainer",
    "BACKEND_MAINTAINER": "Backend Maintainer",
    "BACKEND": "Backend",
    "WIDGET": "Widget",
    "API": "API",
    "DATABASE": "Database",
    "APP": "Application",
    "GAME": "Game",
    "MOBILE_APP": "Mobile App",
    "DESKTOP_APP": "Computer App",
    "ART": "Art",
    "MY_ROLES": "My roles",
    "LINK_TO_WEBSITE": "Link to the website",
    "CONTACTS": "Contacts",
    "CONTACT_ME": "Contact me",
    "SUBMIT": "Submit",
    "LOCATION": "Location",
    "PHONE": "Mobile phone",
    "NAME": "Name",
    "SUBJECT": "Subject",
    "MESSAGE": "Message",
    "WATCHVIDEO": "Watch video",
    "ALOT": "A lot",

    "HOME": "Home",
    "SKILLS": "Skills",
    "MY_OFFERS": "My offers",
    
    "AIRDESK_DESCRIPTION": "All-in-One platform for collaboration and business management.",
    "MANUAISESCOLARES_DESCRIPTION": "Platform that provides free school textbook vouchers for students.",
    "LOYALTY_DESCRIPTION": "E-commerce platform to exchange points for services or products.",
    "GETCOMPLIED_DESCRIPTION": "Widget for websites that helps comply with EU GDPR rules.",
    "BRAINIACCHESS_DESCRIPTION": "Online chess game with crypto currency bets and tournaments.",
    "SMARTCITY_DESCRIPTION": "Platform to report problems in cities for the purpose of being fixed.",
    "WINESOFPORTUGAL_DESCRIPTION": "Platform to spread Portuguese wines around the world.",
    "POUPAENERGIA_DESCRIPTION": "Platform to simulate and compare electricity and natural gas tariffs.",
    "RIADEAVEIRO_DESCRIPTION": "Platform that allows the user to discover the Aveiro estuary and provides routes and activities.",
    "GEOPARQUE_DESCRIPTION": "Platform that allows the user to get to know the Aveiro geopark and provides routes with a geological aspect.",
    "PLANNER_DESCRIPTION": "Customizable component for websites that is used to plan routes.",
    "CAMPING_DESCRIPTION": "Platform backoffice that makes known the network of camping and caravan parks and allows you to make reservations.",
    "GEOPARQUETABLE_DESCRIPTION": "Platform that allows the user to get to know the Aveiro geopark and provides routes with a geological aspect. (Kiosk version)",
    "CASAMAIS_DESCRIPTION": "Platform that helps identify improvement opportunities to be implemented in properties to reduce energy and water consumption.",
    "MULTIWASHER_DESCRIPTION": "Platform that generates PDF flyers according to the data entered in the backoffice.",
    "TIMOR_DESCRIPTION": "Timor-Leste social security platform.",
    "WHISTLEBLOWER_DESCRIPTION": "Platform to make complaints anonymously.",
    "WHISTLEBLOWER_BACKOFFICE_DESCRIPTION": "Platform to make complaints anonymously. (Backend)",
    "OGPREDICT_DESCRIPTION": "Soccer prediction platform that gives you the opportunity to earn rewards for your prediction skills.",
    "CCV_BACKEND_DESCRIPTION": "Cape Verde postal management platform. (Backend)",
    "CCV_APP_DESCRIPTION": "Cape Verde postal management platform. (Desktop App)",
    "INTELCLUE_BACKOFFICE_DESCRIPTION": "Business solution for protecting and encrypting files and data. (Backend)",
    "INTELCLUE_OUTLOOK_DESCRIPTION": "Business solution for protecting and encrypting files and data. (Outlook plugin)",
    "INSS_STRAPI_DESCRIPTION": "Timor-Leste Social Security Portal. (CMS Backoffice - Strapi)",
    "HARDSECURE_DESCRIPTION": "Platform for presenting the company's services.",
    "HARDSECURE_STRAPI_DESCRIPTION": "Platform for presenting the company's services. (CMS Backoffice - Strapi)",

    "WEB_DEVELOPMENT_TEXT": "Personalized, interactive, dynamic, responsive, easy to maintain and unique websites that meet your needs.",
    "SERVER_DEVELOPMENT_TEXT": "Fast and secure APIs, both for games with real-time communication and for websites and applications.",
    "GAME_DEVELOPMENT_TEXT": "Games of all kinds, 2D or 3D, functional for any platform.",
    "DESKTOP_DEVELOPMENT_TEXT": "Applications and services for Windows, Mac and Linux.",
    "MOBILE_DEVELOPMENT_TEXT": "IOS and Android applications with good performance.",

    "HOURS": "Hours",
    "LINESOFCODE": "Lines of code",
    "COFFEES": "Coffees",




    "HOBBY_1_TITLE": "2D Game (Unity)",
    "HOBBY_1_TEXT": "2D platformer game to test the technology.",

    "HOBBY_2_TITLE": "Music Website",
    "HOBBY_2_TEXT": "Website to listen to music and manage local songs on the computer with basic controls (random, play, pause, volume, fast forward, next).",

    "HOBBY_3_TITLE": "Security Cameras",
    "HOBBY_3_TEXT": "Construction of a camera with Raspberry Pi, software and streaming server.",

    "HOBBY_4_TITLE": "2D Game (Godot)",
    "HOBBY_4_TEXT": "Pixel style indie platform game.",
    
    "HOBBY_5_TITLE": "Chess Game",
    "HOBBY_5_TEXT": "Online chess game with crypto currency bets - Radical Chess.",
    
    "HOBBY_6_TITLE": "Anglo Dinero",
    "HOBBY_6_TEXT": "NFT based Learn-to-earn platform that enables users to earn rewards by educating themselves.",
    
    "HOBBY_7_TITLE": "OG Predict",
    "HOBBY_7_TEXT": "Soccer prediction platform that gives you the opportunity to earn rewards for your prediction skills.",

    "PROFESSIONAL_1_TITLE": "Cândido Guerreiro Professional School",
    "PROFESSIONAL_1_TEXT": "Professional Course in Management Informatics - High school.",
    
    "PROFESSIONAL_2_TITLE": "Fullstack Developer",
    "PROFESSIONAL_2_TEXT": "Developer at Algardata S.A developing websites, databases, computer apps and APIs.",
    
    "PROFESSIONAL_3_TITLE": "Front-End Developer",
    "PROFESSIONAL_3_TEXT": "Developer at Ubiwhere developing websites.",
    
    "PROFESSIONAL_4_TITLE": "Fullstack Lead Developer",
    "PROFESSIONAL_4_TEXT": "Developer at Linkwithus developing websites, databases, computer apps, mobile apps and APIs.",
    
    "PROFESSIONAL_5_TITLE": "Fullstack Lead Developer",
    "PROFESSIONAL_5_TEXT": "Developer at PwC Portugal developing websites, databases, computer apps, mobile apps and APIs.",
}