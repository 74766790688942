import React, {useState, useEffect} from 'react';

const CircleBar = ({radius, stroke, subtitle, animate, progress, rotate, color, heatmap, ...props}) => {

    const [currProgress, setCurrProgress] = useState(0);


    useEffect(() => {

        if (currProgress === progress || !animate) return;

        setTimeout(() => {
            var p = currProgress;
            p += 2;

            if (p > progress) p = progress;

            setCurrProgress(p);

        }, 20);

    }, [currProgress, animate, progress]);




    var normalizedRadius = radius - stroke * 2;
    var circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - currProgress / 100 * circumference;



    var getHeatMapColor = (percent) => {
        if (percent <= 10) return "#ff3d03";
        else if (percent <= 20) return "#ff5320";
        else if (percent <= 30) return "#ff6e2a";
        else if (percent <= 40) return "#ff890f";
        else if (percent <= 50) return "#ffaa51";
        else if (percent <= 60) return "#ffcd3f";
        else if (percent <= 70) return "#ffc520";
        else if (percent <= 80) return "#47c54b";
        else if (percent <= 90) return "#50b553";
        else if (percent <= 100) return "#399c3c";
    }



    return <div className="circular-progress" {...props}>
                <div className="text-align-center w100" style={{color: heatmap ? getHeatMapColor(currProgress) : color, marginTop: 33, position: "absolute"}}>
                    <span className="bold" style={{fontSize: 35}}>{currProgress}</span><span style={{fontSize:19}}>%</span>
                </div>
                <svg
                    height={radius * 2}
                    width={radius * 2}
                    style={{transform: `rotate(${rotate || 0}deg)`, position: "relative"}}
                    >
                    <circle
                    stroke={heatmap ? getHeatMapColor(currProgress) : color}
                    fill="transparent"
                    strokeWidth={ stroke }
                    strokeDasharray={ circumference + ' ' + circumference }
                    style={ { strokeDashoffset } }
                    r={ normalizedRadius }
                    cx={ radius }
                    cy={ radius }
                    />
                </svg>
                <div className="title text-align-center" style={{color: "#424242", fontSize: 13, marginTop: -5}}>{subtitle}</div>
            </div>
 
};

export default React.memo(CircleBar);