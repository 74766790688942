import React, {useState} from 'react';
import { EmailIcon } from './Images';

const SubmitButton = ({onClick, validateForm, ...props}) => {

    const [loading, setLoading] = useState(false);

    const [success, setSuccess] = useState(null);

    const minimalTime = 1000;

    const click = () => {

        if (loading || success || success === false) return;

        if (!validateForm()) return;

        setLoading(true);
        const initialDate = new Date();

        onClick((succ) => {
            
            if (new Date() - initialDate < minimalTime) {
                setTimeout(() => {
                    // setLoading(false);
                    setSuccess(succ);

                    if (!succ) setTimeout(() => {
                        setSuccess(null);
                        setLoading(false);
                    }, 2400);
                }, minimalTime - (new Date() - initialDate));
            } 
            else {
                setSuccess(succ);
                // setLoading(false);
                if (!succ) setTimeout(() => {
                    setSuccess(null);
                    setLoading(false);
                }, 2400);
            }

        });
    }

    return <div className={"submit-button no-select" + (loading ? " loading" : "") + (success ? " success" : success === false ? " error" : "")} onClick={click}>
        <EmailIcon color="#424242" />
        <div className="status">
            <EmailIcon color="#424242" />
            <div className="status-dot">
                <div className="status-dot-line-1"></div>
                <div className="status-dot-line-2"></div>
            </div>
        </div>
    </div>
 
};

export default React.memo(SubmitButton);