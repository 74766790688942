/* eslint-disable */
import React, {useState, useEffect, useRef} from 'react';


const IAm = ({textsClassName, animate, delayWhenDone, lang, delayWhenStart, preText, texts, speed, removeSpeed, ...rest}) => 
{
    
    const [currentLang, setCurrentLang] = useState(lang);
    const [currentTextData, setCurrentTextData] = useState({text: 0, phrase: "", isDeleting: false, delay: false, hasBeenDelayed: false});
    const cursor = useRef(null);

    var getNextWord = useRef((data, texts) => {
        // var data = {...currentTextData};

        if (data.delay) return;
        
        if (data.isDeleting && data.phrase.length !== 0)
        {
            data.phrase = data.phrase.substring(0, data.phrase.length - 1);
        }
        else if (data.isDeleting && data.phrase.length === 0)
        {
            data.isDeleting = false;
            data.text++;
            if (data.text === texts.length) data.text = 0;
        }
        else if (data.phrase.length === texts[data.text].length)
        {
            if (!data.hasBeenDelayed) {
                data.delay = true;
            }
            data.isDeleting = true;
            if (!data.delay) data.phrase = data.phrase.substring(0, data.phrase.length - 1);
        }
        else {
            if (data.phrase.length === 0 && !data.hasBeenDelayed) {
                data.delay = true;
            }
            if (!data.delay) data.phrase += texts[data.text][data.phrase.length]
        }
        data.hasBeenDelayed = false;

        return data;

        // setCurrentTextData(data);

        // setTimeout(() => {
        //     formatNextLetter();
        // }, speed);
    }).current;
    

    useEffect(() => {

        if (!animate) return;

        if (currentTextData.delay)
        {
            cursor.current.classList.add("is-not-typing");
            setTimeout(() => {

                if (currentLang !== lang) {
                    // changeLang(lang);
                    setCurrentLang(lang);
                    setCurrentTextData({text: 0, phrase: "", isDeleting: false, delay: false, hasBeenDelayed: false});
                    return;
                }

                var data = {...currentTextData};
                data.delay = false;
                data.hasBeenDelayed = true;
                cursor.current.classList.remove("is-not-typing");
                setCurrentTextData(data);
            }, currentTextData.isDeleting ? delayWhenDone : delayWhenStart);
        }
        else {
            setTimeout(() => {
                if (currentLang !== lang) {
                    setCurrentLang(lang);
                    setCurrentTextData({text: 0, phrase: "", isDeleting: false, delay: false, hasBeenDelayed: false});
                    return;
                }
                const nextData = getNextWord({...currentTextData}, texts);
                setCurrentTextData(nextData)
            }, currentTextData.isDeleting ? removeSpeed : speed);
        }
        
    }, [currentTextData, animate]);

    // if (JSON.stringify(lastTexts) !== JSON.stringify(texts)) {
    //     setLastTexts(texts);
    //     setCurrentTextData({text: 0, phrase: "", isDeleting: false, delay: false, hasBeenDelayed: false});
    // }

    
    

    var letters = currentTextData.phrase.split("");



    return (
        <div {...rest}>
            {preText && <span>{preText}</span>}
            {letters.map((e, i) => 
                <span key={i + e} className={textsClassName}>
                    {e}
                </span>
            )}
            <div ref={cursor} className="text-cursor"></div>
        </div>
    );
}

export default React.memo(IAm);