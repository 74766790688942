import React, {useState, useEffect} from 'react';

const FunnyCounter = ({text, image, progress, slowDown, slowDownStep, slowDownStart, animate, step, finalValue, ...props}) => {

    const [currProgress, setCurrProgress] = useState(0);


    useEffect(() => {

        if (currProgress === progress || !animate) return;

        setTimeout(() => {
            var p = currProgress;

            let valueStep = step;

            if (slowDown && (!slowDownStart || slowDownStart <= p)) {
                const times = (slowDownStart ? (p - slowDownStart) : p) / slowDown;
                
                if (times > 0) {
                    valueStep = parseInt(valueStep - slowDownStep * times);
                }
            }

            p += valueStep;

            if (p > progress) p = progress;

            setCurrProgress(p);

        }, 40);

    }, [currProgress, animate, progress, slowDown, slowDownStart, slowDownStep, step]);




    return <div className="counter-container" {...props}>
                <div className="counter-image">
                    {image}
                </div>
                <div className="counter-number">
                    {finalValue && currProgress === progress ? finalValue : currProgress}
                </div>
                <div className="counter-text">
                    {text}
                </div>
            </div>
 
};

export default React.memo(FunnyCounter);