export const WorkType = {
    All: 0,
    Game: 1,
    Frontend: 2,
    Api: 3,
    Backend: 4,
    App: 5,
    UIUX: 6,
    Widget: 7,
    Database: 8,
    FrontendMaintainer: 9,
    BackendMaintainer: 10,
}

export const MenuTab = {
    Home: 1,
    AboutMe: 2,
    LanguageSkills: 3,
    Tools: 4,
    MyTimeline: 5,
    MyOffers: 6,
    Portfolio: 7,
    Contacts: 8
}